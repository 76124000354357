.aTag:link,
.aTag:visited {
	color: #007bff;
	text-decoration: underline;
	cursor: pointer;
}

.aTag:hover {
	text-decoration: none;
}
