@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

/* @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap'); */
/* reset css */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

* {
	padding: 0;
	margin: 0;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.btn {
	outline: none !important;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

a,
label {
	-webkit-user-select: none;
	/* Chrome/Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+ */
	/* Rules below not implemented in browsers yet */
	-o-user-select: none;
	user-select: none;
}

a:hover {
	cursor: pointer;
	text-decoration: none;
	color: #332ca2;
}

body {
	/* font-family: 'MaisonNeue-Book'; */
	font-family: 'Roboto', sans-serif !important;
}

strong {
	font-weight: 700;
}

.gray-text {
	color: #484848;
}

.red_text {
	color: #ed6060 !important;
}

.green_text {
	color: #2dc8b2 !important;
}

.font18 {
	font-size: 18px;
	line-height: 28px;
}

.font12 {
	font-size: 12px;
	line-height: 20px;
}

.font14 {
	font-size: 14px;
	line-height: 24px;
}

.font16 {
	font-size: 16px !important;
}

.font20 {
	font-size: 20px;
	line-height: 30px;
}

.font24 {
	font-size: 24px;
	line-height: 38px;
}

.c-a1 {
	color: #a1a3b4 !important;
}

p {
	color: #a1a3b4;
	line-height: 25px;
	font-size: 16px;
}

.medium {
	font-family: 'MaisonNeue-Medium';
}

.bold {
	font-family: 'MaisonNeue-Bold';
}

.light {
	font-family: 'MaisonNeue-Light';
}

.extrabold {
	font-weight: 900;
}

textarea {
	resize: none;
}

.verifyMail {
	background-color: #f8dbdb;
	padding: 0.7rem !important;
	display: flex;
}

.verifyMail h5 {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	padding-top: 0.63rem;
	font-weight: 500;
}

.form-control[readonly] {
	opacity: 0.5;
}

.form-control[readonly]:focus {
	opacity: 0.5;
}

/*google map*/

.g-map {
	width: 100%;
}

.g-map iframe {
	width: 400px;
	height: 250px;
	border-radius: 15px;
}

/*custom scoll*/

/*sticky footer*/

.site {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	overflow-x: hidden;
}

.wrapper {
	flex: 1 0 auto;
	/*overflow-x: hidden;*/
}

.c-trialheader{
	padding: 8px;
	border-radius: 6px;
}

/*********default css*********/

/**********placeholder*************/

.form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bcbccb;
}

.form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #bcbccb;
}

.form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #bcbccb;
}

.form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #bcbccb;
}

.form-control:placeholder {
	/* Firefox 18- */
	color: #bcbccb;
}

.pt-80 {
	padding-top: 80px;
}

.pb-80 {
	padding-bottom: 80px;
}

.blue_text {
	color: #332ca2 !important;
}

b {
	font-family: 'MaisonNeue-Bold';
}

/*********header*********/

header .navbar-nav .nav-item {
	align-self: center;
}

header .navbar-nav .nav-link {
	color: #6f7c7c;
	padding: 0 18px !important;
	align-self: center;
	font-family: 'MaisonNeue-Medium';
	font-size: 14px;
}

header nav {
	background-color: #fff;
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
}

header .navbar-nav .nav-link:hover,
header .navbar-nav .nav-link.active {
	color: #332ca2;
}

.navbar-brand img {
	width: auto;
	max-width: 200px;
}

header .dropdown-toggle::after {
	display: none;
}

header .dropdown-menu {
	left: auto;
	right: 0;
	border: none;
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
}

header .dropdown-menu .dropdown-item {
	padding: 6px 15px;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #332ca2;
	color: #fff;
}

.dropdown-item:focus {
	outline: none;
}

header .nav-link.header_btn {
	background: #332ca2;
	color: #fff;
	margin: 0 10px 0 15px;
	border-radius: 6px;
	padding: 4px 15px !important;
	border: 2px solid #332ca2;
}

header .nav-link.signup_btn {
	background: #fff;
	color: #332ca2;
	margin-left: 15px;
	border-radius: 6px;
	padding: 4px 15px !important;
	border: 2px solid #332ca2;
}

header .nav-link.header_btn:hover {
	background: #fff;
	color: #332ca2;
}

header .nav-link.signup_btn:hover {
	background: #332ca2;
	color: #fff;
}

/***
******custom radio checkbox*********/

/* The container */

.checkContainer {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 20px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #4a5271;
}

/* Hide the browser's default checkbox */

.checkContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */

.checkContainer .checkmark {
	position: absolute;
	top: -3px;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: transparent;
	border-radius: 5px;
	border: 1px solid #bcbccb;
}

/* On mouse-over, add a grey background color */

.checkContainer:hover input ~ .checkmark {
	background-color: #eee;
}

/* When the checkbox is checked, add a blue background */

.checkContainer input:checked ~ .checkmark {
	background-color: #332ca2;
	border: 1px solid #332ca2;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkContainer .checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */

.checkContainer input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */

.checkContainer .checkmark:after {
	left: 6px;
	top: 2px;
	width: 6px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* The radio */

.radioContainer {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 16px;
	color: #6f7c7c;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */

.radioContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */

.radioContainer .checkmark {
	position: absolute;
	top: -5px;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #6f7c7c;
}

/* On mouse-over, add a grey background color */

.radioContainer:hover input ~ .checkmark {
	background-color: #f2f2f2;
}

/* When the radio button is checked, add a blue background */

.radioContainer input:checked ~ .checkmark {
	background-color: #fff;
	border-color: #332ca2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radioContainer .checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */

.radioContainer input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */

.radioContainer .checkmark:after {
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #332ca2;
}

/*********Landing section*********/

.page_content {
	padding-top: 70px;
}

.custom-container {
	padding: 0 30px;
	width: 80%;
	margin: 0 auto;
}

.navbar > .container-fluid {
	width: 80%;
}

h1 {
	font-size: 44px;
	color: #fff;
	letter-spacing: 1px;
	margin-bottom: 20px;
	line-height: 60px;
}

.landing_sec .content p {
	color: #fff;
	margin-bottom: 30px;
	line-height: 30px;
}

.btn {
	padding: 8px 20px;
	font-size: 18px;
	/* font-family: 'MaisonNeue-Medium'; */
	border-radius: 8px;
	min-width: 100px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #fff !important;
	color: #332ca2 !important;
	box-shadow: none;
	border-color: #332ca2 !important;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary:disabled {
	background-color: #332ca2 !important;
	border: 2px solid #332ca2 !important;
	box-shadow: 0 3px 6px rgba(52, 44, 162, 0.3);
	color: #fff !important;
}

.btn.disabled,
.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.btn-cyan:hover,
.btn-cyan:focus,
.btn-cyan:active {
	background-color: #fff;
	color: #31cce5;
	box-shadow: none;
}

.btn-cyan,
.btn-cyan.disabled:hover,
.btn-cyan.disabled:focus,
.btn-cyan:disabled {
	background-color: #31cce5;
	min-width: 150px;
	border: 2px solid #31cce5;
	color: #fff;
	box-shadow: 0 3px 6px rgba(49, 204, 229, 0.3);
}

.btn-blue-stroke {
	border: 2px solid #332ca2;
	color: #332ca2;
	background-color: #fff;
	min-width: 150px;
}

.btn-blue-stroke:hover {
	background-color: #332ca2;
	color: #fff;
}

.btn-white-stroke {
	border: 2px solid #fff;
	color: #fff;
	background-color: transparent;
	min-width: 150px;
}

.btn-white-stroke:hover {
	background-color: #fff;
	color: #332ca2;
}

.btn-gray:hover {
	background-color: #fff;
	border-color: #9fa3b1;
}

.btn-gray,
.btn-gray.disabled:hover,
.btn-gray.disabled:focus,
.btn-gray:disabled {
	border: 2px solid #f3f4f6;
	color: #9fa3b1;
	background-color: #f3f4f6;
	min-width: 150px;
}

.green_stroke_btn {
	border: 2px solid #4fb14c;
	color: #4fb14c;
}

.green_stroke_btn:hover {
	background-color: #4fb14c;
}

.landing_sec .banner {
	padding: 50px 0;
	height: 800px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

/*********Feature section*********/

.feature_box {
	background-color: #fff;
	box-shadow: 0 6px 24px rgba(202, 215, 225, 0.5);
	padding: 30px;
	border-radius: 15px;
	height: 240px;
	overflow: hidden;
	transition: 0.2s ease-in-out;
	transform: translateY(0);
	margin-bottom: 30px;
}

.feature_box .feature_icon {
	margin-bottom: 20px;
}

h3 {
	color: #4a5271;
	font-size: 38px;
	margin-bottom: 20px;
	line-height: 40px;
}

.feature_box p {
	margin-bottom: 20px;
}

.feature_box .link {
	font-size: 18px;
	font-family: 'MaisonNeue-Medium';
	color: #fff;
	transition: 0.2s ease-in-out;
	visibility: hidden;
}

.feature_box:hover {
	background-image: linear-gradient(to left, #332ca2, #31cce5);
	background-color: transparent;
}

.feature_box .content {
	transition: 0.2s ease-in-out;
	transform: translateY(0);
}

.feature_box:hover .content {
	transform: translateY(-60px);
	transition: 0.2s ease-in-out;
}

.feature_box:hover .content .feature_icon {
	visibility: hidden;
}

.feature_box:hover .link {
	visibility: visible;
}

.feature_box:hover h3,
.feature_box:hover p {
	color: #fff;
}

.feature_box .link img {
	vertical-align: middle;
	margin-left: 8px;
	transition: 0.2s ease-in-out;
}

.feature_box .link:hover img {
	margin-left: 15px;
	transition: 0.2s ease-in-out;
}

/*.feature_sec .feature_listing .sec_margin{ margin-top: 50px }*/

h2 {
	font-size: 44px;
	text-transform: uppercase;
	margin-bottom: 30px;
	text-align: center;
	color: #4a5271;
}

/********about sec********/

.about_detail .content {
	width: 80%;
}

.about_detail .bg_img {
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
	box-shadow: 0 6px 24px rgba(202, 215, 225, 0.5);
	height: 300px;
	width: 500px;
	max-width: 100%;
	position: relative;
	margin-top: 50px;
}

.about_detail .bg_img:after {
	position: absolute;
	height: 100%;
	width: 100%;
	background: #332ca2;
	opacity: 0.08;
	transform: translate(-50px, -50px);
	content: '';
	border-radius: 15px;
	z-index: -1;
}

.about_detail .bg_img.left_img {
	margin-left: 50px;
}

.about_detail .bg_img.right_img {
	float: right;
}

.about_detail .margin_bottom {
	margin-bottom: 80px;
}

/*******blue_bg section ********/

.bgWarning {
	background-color: #ff4848 !important;
	place-content: center;
	font-size: 12px;
	color: #fff;
	height: 40px !important;
}

.warningButton{
	background-color: #feff15 !important;
	color: #ff4848 !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	padding: 4px !important;
}

.bg_cyan {
	background-image: linear-gradient(to left, #332ca2, #31cce5);
}

.cyan_text {
	color: #1c72eb;
}

.join_sec .btn,
.email_sec .btn {
	float: right;
}

/*******screen shot section********/

.screenshot_sec .bg_img {
	padding-top: 56%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
}

.screenshot_sec .bg_img.full_img {
	padding-top: 100%;
	height: 100%;
}

.screenshot_sec .bg_img.mb-3 {
	margin-bottom: 30px !important;
}

.screenshot_sec .bg_img .btn {
	position: absolute;
	left: 20px;
	bottom: 20px;
}

.screenshot_sec .bg_img .overlay {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	background-image: linear-gradient(
		0deg,
		rgba(16, 16, 16, 0.34),
		rgba(255, 255, 255, 0.34)
	);
}

.hover_link {
	transition: 0.2s ease-in-out;
}

.hover_link:hover img {
	margin-left: 15px;
	transition: 0.2s ease-in-out;
}

.hover_link img {
	vertical-align: middle;
	margin-left: 8px;
	transition: 0.2s ease-in-out;
}

h5 {
	font-size: 28px;
	color: #4a5271;
	margin-bottom: 15px;
}

.info_box .icon {
	width: 50px;
	margin-right: 15px;
	display: inline-block;
	vertical-align: top;
	margin-top: 3px;
}

.info_box .desc {
	width: calc(85% - 70px);
	display: inline-block;
	vertical-align: top;
}

.part_of_service .content {
	padding-right: 25%;
}

.part_of_service .sec_img {
	width: 95%;
}

.email_sec .custom-container {
	width: 60%;
}

/********footer********/

footer h5 {
	color: #4a5271;
	margin-bottom: 35px;
	margin-top: 15px;
}

footer .contact_row img {
	margin-top: 2px;
	margin-right: 5px;
}

footer .contact_row {
	margin-bottom: 15px;
}

footer .contact_row img,
footer .contact_row p {
	display: inline-block;
	vertical-align: top;
	line-height: 25px;
}

footer ul li {
	margin-bottom: 20px;
}

footer ul li a {
	color: #818e94;
}

footer .footer_bottom {
	border-top: 1px solid rgba(188, 188, 203, 0.2);
	padding: 30px 0;
}

/*******modal*******/

#add_materials .modal_height {
	max-height: 320px;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.modal .head h2 {
	font-size: 30px;
}

.modal_padding {
	padding: 20px 50px;
}

form .control_label {
	font-size: 14px;
	font-family: 'MaisonNeue-Medium';
	margin-bottom: 10px;
	width: 100%;
	color: #4a5271;
}

.form-control {
	border: 1px solid rgba(188, 188, 203, 0.5);
	border-radius: 6px;
	height: auto;
	padding: 10px 15px;
}

.submit_btn {
	display: inline-block;
	width: 100%;
}

.black_text {
	color: #263238;
}

.modal .close {
	position: absolute;
	right: 25px;
	top: 40px;
	line-height: normal;
	font-size: 0;
	text-shadow: none;
	font-weight: normal;
	z-index: 1;
	width: 24px;
	opacity: 1;
}

.close:focus {
	outline: none;
}

.modal-content {
	border-radius: 20px;
}

.modal .btn {
	min-width: 115px;
}

.signup_card {
	width: 90%;
	margin: 0 auto;
	padding: 50px 0;
}

.signup_card .card_box {
	width: 100%;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	height: 100%;
	padding: 40px 15px;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.signup_card .card_box:hover,
.signup_card .card_box.selected {
	background-color: #eafafd;
	box-shadow: 0px 1px 3px rgba(49, 204, 229, 0.3);
}

.signup_card .check_circle {
	position: absolute;
	right: 15px;
	top: 15px;
	background: #332ca2;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	display: none;
}

.signup_card .check_circle:after {
	left: 6px;
	top: 3px;
	width: 7px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	content: '';
	position: absolute;
}

.signup_card .selected .check_circle {
	display: block;
}

.title {
	margin: 15px 0;
	color: #4a5271;
	font-size: 20px;
}

.signup_card .icon {
	height: 70px;
	width: 100%;
	display: flex;
}

.signup_card .icon img {
	align-self: center;
	margin: 0 auto;
}

.modal_footer {
	border-top: 1px solid rgba(188, 188, 203, 0.35);
	padding-top: 30px;
}

.addr_icon {
	position: relative;
}

.addr_icon .icon {
	position: absolute;
	right: 10px;
	top: 14px;
}

.select2-container {
	width: 100% !important;
}

.select2-container .select2-selection--single {
	padding: 8px 15px;
	border: 1px solid rgba(188, 188, 203, 0.5);
	height: 45px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding: 0;
}

.select2.select2-container.select2-container--open .select2-selection--single {
	background-image: url('../images/up.png');
}

.select2.select2-container .select2-selection--single {
	background-image: url('../images/Back.png');
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: right center;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	visibility: hidden;
}

.phone_no .code {
	width: 80px;
	display: inline-block;
}

.phone_no {
	width: 100%;
	display: inline-block;
}

.phone_no .number {
	width: calc(100% - 92px);
	margin-left: 8px;
	display: inline-block;
}

.form-group {
	margin-bottom: 20px;
}

.verifiction_block input {
	width: 100%;
	border: none;
	border-bottom: 1px solid #ddd;
	text-align: center;
	padding: 6px 0;
	margin-bottom: 20px;
}

.verifiction_block input:focus {
	outline: none;
}

.verifiction_block {
	padding: 8vh 0;
	width: 80%;
	margin: 0 auto;
}

.verifiction_block .otp-input {
	width: 100% !important;
	border: none;
	border-bottom: 1px solid #ddd;
	text-align: center;
	padding: 6px 0;
	margin-bottom: 20px;
	margin-right: 10px;
}

.upload_pic {
	width: auto;
	margin: 0 auto;
	display: table;
	position: relative;
}

.upload_pic .profile_img {
	object-fit: cover;
	border-radius: 20px;
	height: 100px;
	width: 100px;
	object-position: center;
	border: 2px solid #b8c4d6;
}

.upload_pic .upload_icon {
	position: absolute;
	right: -12px;
	bottom: -8px;
	background: #b8c4d6;
	border-radius: 100%;
	width: 44px;
	height: 44px;
	text-align: center;
	padding: 10px 0;
	color: #fff !important;
	font-size: 18px;
	border: 4px solid #fff;
}

.upload_pic .upload_icon:hover {
	background-color: #abb6c5;
}

.dark_text {
	color: #4a5271;
}

.information {
	margin: 40px auto 0;
	padding-left: 35%;
}

.information .info_row {
	width: 100%;
	display: inline-block;
	margin-bottom: 15px;
}

.information .info_row .detail {
	width: calc(100% - 30px);
	display: inline-block;
	vertical-align: top;
	padding-left: 10px;
}

.information .info_row img {
	display: inline-block;
	margin-top: 2px;
}

.payment_method.signup_card {
	padding: 30px 0;
}

.payment_method.signup_card .card_box {
	padding-bottom: 30px;
}

.payment_method.signup_card .card_box img {
	width: 79px;
	height: auto;
	margin-bottom: 15px;
}

.branch_row {
	border-bottom: 1px solid rgba(188, 188, 203, 0.37);
	padding: 15px 0;
}

.branch_row,
.branch_row .branch_detail .user_block {
	width: 100%;
	display: inline-block;
}

.branch_row:last-child {
	border-bottom: none;
}

.branch_row .branch_detail {
	width: calc(100% - 150px);
	display: inline-block;
	vertical-align: middle;
}

.branch_row .branch_detail .user_block img {
	width: 46px;
	height: 46px;
	border-radius: 8px;
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
}

.branch_row .branch_detail .user_block .user_detail {
	display: inline-block;
	width: calc(100% - 65px);
	vertical-align: middle;
}

.branch_action_btn {
	display: inline-block;
	width: 140px;
	vertical-align: middle;
}

.branch_action_btn .edit_delete {
	width: 42px;
	height: 42px;
	text-align: center;
	display: inline-block;
	padding: 10px;
	background: #f6f6fb;
	border-radius: 8px;
}

.branch_action_btn .btn-blue-stroke {
	font-size: 12px;
	text-transform: uppercase;
	min-width: auto;
}

.after_login_header {
	height: 70px;
	background: #fff;
	/*box-shadow: 1px 0px 6px rgba(0,0,0,0.1); */
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1030;
}

.sidebar {
	width: 80px;
	position: fixed;
	top: 0;
	height: 100vh;
	background-color: #fff;
	padding-top: 0;
	z-index: 1040;
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
	padding-bottom: 120px;
}

.header_navbar {
	display: flex;
}

.header_navbar ul {
	margin-left: auto;
	margin-right: 15px;
}

.header_navbar ul li {
	display: inline-block;
	margin: 0 15px;
}

.header_navbar ul li a {
	color: #000;
}

.header_navbar ul li a i {
	font-size: 30px;
	vertical-align: middle;
}

.header_navbar .user_block {
	margin: 5px 0;
}

.header_navbar .user_block .user_detail {
	display: inline-block;
	vertical-align: middle;
}

.header_navbar .user_block .user_detail .name {
	font-size: 20px;
	color: #000;
}

.header_navbar .user_block img {
	width: 50px;
	height: 50px;
	object-fit: cover;
	display: inline-block;
	border-radius: 100%;
	vertical-align: middle;
	margin-right: 5px;
}

.header_navbar .user_block {
	margin: 15px 0 0;
}

header.after_login_header nav {
	box-shadow: none;
}

.sidebar .bottom_fixed {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.sidebar .sidebar-scroll {
	height: calc(100vh - 200px);
}

#content {
	width: 100%;
	min-height: 100vh;
	transition: all 0.3s;
	padding-left: 80px;
	padding-top: 70px;
	background: #f5f9fc;
}

.content_padding {
	padding: 40px 30px;
}

.sidebar .navbar-brand {
	margin: 15px auto;
	display: table;
}

.sidebar ul li a {
	display: inline-block;
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-size: 34px;
	width: 34px;
	height: 29px;
}

.sidebar ul li {
	text-align: center;
	padding: 10px 0;
	position: relative;
	margin: 5px 0;
}

.sidebar ul li a:hover,
.sidebar ul li.active a {
	background-position: -0 -29px;
}

.sidebar ul li.active:after {
	background: #332ca2;
	left: 4px;
	top: 0;
	bottom: 0;
	content: '';
	position: absolute;
	width: 4px;
	box-shadow: 0 0 6px rgba(51, 44, 162, 0.8);
}

.head_calender input {
	font-size: 32px;
	color: #4a5271;
	background: transparent;
	border: none;
	width: 200px;
	box-shadow: none;
}

.head_calender input:focus {
	outline: none;
}

.head_calender img {
	display: inline-block;
	margin-left: 5px;
	margin-top: 5px;
}

.btn-sm {
	padding: 5px 15px;
	font-size: 16px;
	min-width: auto;
}

.right_part_btns .btn-group .btn:last-child {
	border-right: none;
}

.right_part_btns .btn-group .btn {
	background: #fff;
	margin-left: 0;
	color: #bcbccb;
	border-right: 1px solid #efefef;
	min-width: 100px;
}

.right_part_btns .btn-group .btn:hover,
.right_part_btns .btn-group .btn.selected {
	background-color: #332ca2;
	color: #fff;
	box-shadow: 0 3px 6px rgba(52, 44, 162, 0.3);
}

.light_text {
	color: #bcbccb;
}

.card_view {
	background: #fff;
	border-radius: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
	padding: 25px;
	margin: 20px 0;
}

.card_heading {
	font-size: 18px;
}

.progress {
	border: 1px solid rgba(188, 188, 203, 0.2);
	padding: 3px;
	height: 30px;
	background: #fff;
	border-radius: 15px;
}

.progress .progress-bar {
	background-color: #31cce5;
	border-radius: 15px;
}

.card_header {
	width: 100%;
	display: inline-block;
}

.card_header .card_heading {
	width: calc(100% - 100px);
	display: inline-block;
	color: #4a5271;
}

/* .custom_tab .nav-tabs .nav-item.show .nav-link,
.custom_tab .nav-tabs .nav-link.active {
	border-color: #332ca2;
	color: #332ca2;
} */

/* .custom_tab .nav-tabs .nav-link {
	border: none;
	border-bottom: 3px solid transparent;
	padding-left: 0;
	padding-right: 0;
	margin: 0 15px;
	color: #bcbccb;
} */

/* .custom_tab .nav-tabs .nav-item:first-child .nav-link {
	margin-left: 0;
} */

.errorMsg {
	margin-top: 5px;
	color: #e74c3c;
	display: inline-block;
}

.selected_check {
	height: 22px;
	width: 22px;
	border-radius: 100%;
	border: 1px solid #bcbccb;
	position: relative;
}

.selected .selected_check:after {
	left: 6px;
	top: 3px;
	width: 7px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	content: '';
	position: absolute;
}

.selected .selected_check {
	background: #332ca2;
	border: 1px solid #332ca2;
}

.choose_service_sec .service_box {
	width: 100%;
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	cursor: pointer;
	border-radius: 12px;
}

.choose_service_sec .service_box.selected {
	background-color: #eafafd;
	box-shadow: 0 1px 3px rgba(49, 204, 229, 0.3);
}

.choose_service_sec .service_box img {
	display: inline-block;
	vertical-align: middle;
}

.choose_service_sec .service_box p {
	display: inline-block;
	vertical-align: middle;
	padding-left: 15px;
	width: calc(100% - 80px);
}

.choose_service_sec .service_box .selected_check {
	display: inline-block;
	vertical-align: middle;
}

.custom_tab .tab-pane {
	padding: 0;
}

.cust_user_block .user_img,
.cust_user_block .user_detail {
	display: inline-block;
	vertical-align: middle;
}

.cust_user_block .user_img img {
	width: 52px;
	height: 52px;
	border-radius: 100%;
	margin-right: 15px;
}

.cust_user_block .user_detail {
	width: calc(100% - 80px);
}

.green_text,
a.green_text:hover {
	color: #1abc9c;
}

.yellow_text,
a.yellow_text:hover {
	color: #f1c40f;
}

.red_text,
a.red_text:hover {
	color: #e74c3c;
}

.green_bg {
	background-color: #1abc9c;
}

.blue_bg {
	background-color: #3988e3;
}

.yellow_bg {
	background-color: #f1c40f;
}

.red_bg {
	background-color: #e74c3c;
}

.skyblue_bg {
	background-color: #3498db;
}

.job_status_list .service_name {
	width: auto;
	display: inline-block;
	padding: 6px 10px;
	border-radius: 25px;
	color: #fff;
}

.job_status_list .service_name img,
.job_status_list .service_name span {
	vertical-align: middle;
}

.btn-gray-stroke {
	border: 1px solid #bcbccb;
	color: #bcbccb;
	padding: 5px 15px;
	min-width: auto;
	border-radius: 8px;
	font-size: 14px;
	background: transparent;
}

.btn-gray-stroke:hover {
	background-color: #bcbccb;
	color: #fff;
}

.list_row {
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.dashbox_height {
	max-height: 230px;
	overflow-y: auto;
	overflow-x: hidden;
}

.dashbox_height_100 {
	max-height: 282px;
	overflow-y: auto;
	overflow-x: hidden;
}

.link_underline {
	text-decoration: underline !important;
}

.unassign_list .cust_user_block {
	width: calc(100% - 50px);
	display: inline-block;
	vertical-align: middle;
}

.unassign_list .service_icon {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 100%;
	padding-top: 10px;
	text-align: center;
}

.staff_status_list .cust_user_block {
	width: calc(100% - 100px);
	display: inline-block;
	vertical-align: middle;
}

.staff_status_list .status_div {
	display: inline-block;
	vertical-align: middle;
	text-align: right;
	width: 90px;
}

.staff_status_list .status_div .status {
	width: auto;
	padding: 6px 10px;
	border-radius: 15px;
	font-size: 14px;
	display: inline-block;
}

.red_status {
	color: #e74c3c;
	background-color: #fce9e7;
}

.green_status {
	color: #2fd277;
	background-color: #e5faee;
}

.gray_status {
	color: #9fa3b1;
	background-color: #f3f4f6;
}

.paid_jobs_list .service_icon {
	width: 24px;
	height: 24px;
	display: inline-block;
	border-radius: 100%;
	padding-top: 6px;
	text-align: center;
}

.paid_jobs_list .service_name_icon span {
	display: inline-block;
	line-height: normal;
	margin-left: 2px;
	vertical-align: middle;
	width: calc(100% - 30px);
}

.paid_jobs_list .cust_user_block {
	width: calc(100% - 100px);
	display: inline-block;
	vertical-align: top;
}

.paid_jobs_list .job_price {
	width: 90px;
	display: inline-block;
	vertical-align: top;
	text-align: right;
}

.graph_indicators {
	width: 120px;
}

.graph_indicators .job_indicators {
	margin-bottom: 15px;
	display: inline-block;
	width: 100%;
	position: relative;
	padding-left: 25px;
}

.graph_indicators .job_indicators .job_indicator_line {
	position: absolute;
	left: 0;
	width: 10px;
	height: 100%;
}

.graph_indicators .job_indicators.paid_job .job_indicator_line {
	background-color: #f7aa9a;
}

.graph_indicators .job_indicators.unpaid_job .job_indicator_line {
	background-color: #f05c3f;
}

.admin_heading {
	font-size: 32px;
	line-height: normal;
}

.page_header .right_part_btns input {
	width: 500px;
	padding: 6px 15px;
}

.page_header .right_part_btns .btn,
.page_header .right_part_btns .search_box {
	display: inline-block;
	vertical-align: top;
}

.page_header {
	border-bottom: 1px solid #eaeaea;
}

.page_header .search_box .search_icon {
	position: absolute;
	right: 15px;
	top: 11px;
}

.page_header {
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 15px;
	margin-bottom: 15px;
}

/********table css*******/

.table-bordered td,
.table-bordered th {
	border: 1px solid #eaeaea;
}

.table thead th {
	border-bottom: 2px solid #eaeaea;
	font-size: 18px;
	white-space: nowrap;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #f5f6f7;
}

.table td,
.table th {
	padding: 20px;
	color: #797a7d;
	vertical-align: middle !important;
	line-height: 24px;
}

.progress-tracker .progress-step .progress-marker {
	width: 180px;
}

/* .page_header .nav-tabs .nav-link {
	font-size: 20px;
} */

.card_view_header_block {
	display: flex;
	width: 100%;
}

.card_view_header_block .search_box {
	flex: 1;
	display: flex;
}

.card_view_header_block .search_box input {
	padding-left: 50px;
}

.card_view_header_block .search_box .search_icon {
	position: absolute;
	left: 15px;
	top: 15px;
}

.card_view form .control_label {
	font-size: 16px;
}

.table_user_block img {
	width: 36px;
	height: 36px;
	display: inline-block;
	vertical-align: middle;
}

.table_user_block_new img {
	width: 30px;
	height: 30px;
	display: inline-block;
	vertical-align: middle;
}

.table_user_block p,
.table_user_block_new p {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	width: calc(100% - 50px);
}

.rating i {
	color: #fca016;
	margin: 0 3px;
}

.page_sub_heading {
	color: #bcbccb;
	font-size: 20px;
}

.progress-tracker {
	display: flex;
	flex: 1 1 0%;
	margin-top: 20px;
}

.progress-tracker .progress-step {
	flex: 1 1 0%;
	position: relative;
}

.progress-tracker .progress-step:last-child {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.progress-tracker .progress-step .step_detail {
	display: inline-block;
	width: 100%;
}

.progress-step .progress-marker .dot {
	width: 60px;
	height: 60px;
	/*background: #D9DAE1;border: 2px solid #A1A3B4;*/
	border-radius: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
}

.progress-step .progress-marker::after {
	content: '';
	border: 1px dashed #b5b9bb;
	display: block;
	position: absolute;
	z-index: 1;
	top: 25px;
	right: -95px;
	width: 100%;
	height: 2px;
	-webkit-transition: background-color 0.3s, background-position 0.3s;
	transition: background-color 0.3s, background-position 0.3s;
}

/*.progress-step.is-active .progress-marker .dot:before , .progress-step.is-complete .progress-marker .dot:before{ left: 0; right: 0; margin: 0 auto;top: 7px;width: 14px;height: 25px; border: solid #fff; border-width: 0 4px 4px 0;-webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); position: absolute;content: "";}*/

.progress-step.is-active .progress-marker .dot,
.progress-step.is-complete .progress-marker .dot {
	/*border-color: #332CA2;background-color:#B3B1DD*/
}

/*.progress-step .progress-marker::after {background-color: #3D505A;}*/

.progress-step:last-child .progress-marker::after {
	display: none;
}

.progress-step .progress-marker label {
	color: #4a5271;
	opacity: 1;
	font-size: 18px;
	margin-top: 10px;
	text-align: center;
	width: 200px;
	word-break: break-word;
}

.progress-step .progress-marker label span {
	color: #9fa3b1;
	font-size: 14px;
	line-height: 25px;
}

.card_title {
	font-size: 20px;
	color: #4a5271;
}

.with_border {
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.cust_user_block.big_img .user_img img {
	width: 70px;
	height: 70px;
}

.cust_user_block.big_img .user_detail {
	width: calc(100% - 90px);
}

.total_amount p {
	display: inline-block;
	width: 150px;
	vertical-align: middle;
}

.invoice_detail .que_ans p {
	display: inline-block;
}

.invoice_detail .que_ans .q {
	width: 150px;
}

.invoice_detail .que_ans .a {
	text-align: right;
	width: calc(100% - 154px);
}

.service_row.with_border {
	margin-bottom: 12px;
	padding-bottom: 8px;
}

form .custom_radio label {
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	margin-bottom: 10px;
	margin-top: 5px;
	min-width: 110px;
}

.upload_user_photo img {
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px;
	width: 86px;
	height: 86px;
	border-radius: 100%;
}

.identity_proof img {
	max-height: 110px;
	border-radius: 25px;
	cursor: pointer;
}

.cust_info_sec .inline_div {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	margin: 0 10px;
	position: relative;
	padding: 0 10px;
}

.cust_info_sec .user_block {
	margin: 0 15px 0 0;
	padding: 0 15px 0 0;
	text-align: left;
	white-space: nowrap;
}

.cust_info_sec .single_row_info {
	width: 100%;
	display: inline-block;
	margin-bottom: 20px;
}

.cust_info_sec .user_block .user_img,
.cust_info_sec .user_block .user_detail {
	display: inline-block;
	vertical-align: middle;
}

.cust_info_sec .user_block .user_img img {
	margin-right: 15px;
}

.cust_info_sec .user_block .user_detail {
	width: calc(100% - 110px);
}

.cust_info_sec .inline_div.user_block:after {
	display: none;
}

.cust_info_sec .inline_div:after {
	position: absolute;
	content: '';
	background: #eaeaea;
	height: 50px;
	width: 1px;
	top: -2px;
	left: -12px;
}

.cust_info_sec .que_ans .single_row {
	width: 100%;
	display: inline-block;
	margin-bottom: 8px;
}

.cust_info_sec .que_ans p {
	display: inline-block;
	vertical-align: middle;
}

.cust_info_sec .que_ans .q {
	width: 200px;
}

.cust_info_sec .que_ans .a {
	width: calc(100% - 210px);
}

.contract_term {
	background: #f5f6f7;
	border-radius: 8px;
	padding: 15px;
	margin-top: 20px;
}

.notification_row {
	width: 100%;
	display: inline-block;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eaeaea;
}

.notification_row img {
	margin-right: 15px;
	width: 50px;
	height: 50px;
}

.notification_row img,
.notification_row .user_detail {
	display: inline-block;
	vertical-align: top;
}

.notification_row .user_detail {
	width: calc(100% - 70px);
}

.lan_row {
	width: 100%;
	display: inline-block;
	margin-bottom: 15px;
}

.lan_row .lan_name {
	width: calc(100% - 50px);
}

.lan_row .lan_name,
.lan_row .radioContainer {
	display: inline-block;
	vertical-align: middle;
}

.lan_row .radioContainer {
	margin-top: -12px;
}

.account_info .upload_user_photo .upload_text {
	margin-top: 5px;
	display: block;
}

.cust_info_sec.account_info .que_ans .q {
	width: 300px;
}

.cust_info_sec.account_info .que_ans .a {
	width: calc(100% - 305px);
}

.on_off_switch .switch input {
	display: none;
}

.on_off_switch .switch {
	display: inline-block;
	width: 55px;
	height: 30px;
	margin: 8px;
	/*transform:translateY(50%);*/
	position: relative;
}

.on_off_switch .slider {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 30px;
	box-shadow: 0 0 0 1px #9fa3b1, 0 0 2px #9fa3b1;
	cursor: pointer;
	border: 3px solid transparent;
	overflow: hidden;
	transition: 0.4s;
	background: #d1d3d9;
}

.on_off_switch .slider:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 20px;
	transform: translateX(-25px);
	transition: 0.4s;
}

.on_off_switch input:checked + .slider:before {
	transform: translateX(25px);
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.on_off_switch input:checked + .slider {
	box-shadow: 0 0 0 2px #332ca2, 0 0 2px #332ca2;
	background: #332ca2;
}

.user_control_sec .control_row .on_off_switch {
	display: inline-block;
	vertical-align: top;
	height: auto;
	margin-top: 8px;
	float: right;
}

.user_control_sec .control_row .detail {
	width: calc(100% - 100px);
	display: inline-block;
}

.user_control_sec .control_row {
	display: inline-block;
	width: 100%;
	margin-bottom: 15px;
}

.general_settings {
	width: 100%;
	display: inline-block;
}

.tax_rates .control_row .tax_amount {
	display: inline-block;
}

.user_control_sec.tax_rates .control_row .detail {
	width: calc(100% - 50%);
}

.branches_sec .user_block {
	margin-bottom: 20px;
	width: 100%;
	display: inline-block;
}

.branches_sec .user_block:hover .dark_text {
	color: #332ca2;
}

.branches_sec .user_block img {
	margin-right: 10px;
	width: 65px;
	height: 65px;
}

.branches_sec .user_block .user_detail {
	width: calc(100% - 90px);
}

.branches_sec .user_block img,
.branches_sec .user_block .user_detail {
	display: inline-block;
	vertical-align: middle;
}

.branch_scroll {
	max-height: calc(100vh - 300px);
	overflow-y: auto;
}

.border-right {
	border-right: 1px solid #eaeaea !important;
}

.admin_list .user_block img {
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	margin-right: 10px;
}

.admin_list .user_block .user_detail {
	width: calc(100% - 65px);
	display: inline-block;
	vertical-align: middle;
}

.admin_row {
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.admin_row:last-child {
	border: none;
	padding: 0;
}

.select2-container--default
	.select2-results__option--highlighted[aria-selected] {
	background-color: #332ca2;
}

.fc-header {
	display: none;
}

.fc-agenda-slots th,
.fc-agenda-axis {
	width: 60px !important;
	border-bottom: 0px !important;
	border-top: 0px !important;
	border-left: 0px !important;
	color: #a3a3a3;
	font-size: 14px;
}

.fc-widget-header,
.fc-widget-content {
	border-color: #e2e2e2 !important;
}

thead .fc-widget-header {
	height: 50px;
	vertical-align: middle !important;
}

.fc-today {
	background: #fff !important;
	/* border: none !important; */
	border-top: 1px solid #ddd !important;
	font-weight: bold;
}

.center_feature_sec {
	position: relative;
}

.center_feature_sec::after {
	position: absolute;
	width: 500px;
	margin: 0 auto;
	left: 0;
	right: 0;
	background: #4a5271;
	content: '';
	height: 1px;
	bottom: 55px;
}

/*.feature_screenshot .feature_detail{margin: 30px 0}*/

.feature_detail .feature_title {
	color: #4a5271;
	font-size: 26px;
	margin-bottom: 15px;
}

.feature_bg {
	background: #f5f6f9;
}

.blog_box {
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	margin: 15px 0;
}

.blog_box .img {
	background-size: cover;
	height: 290px;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 10px;
}

.blog_box .desc {
	padding: 15px;
}

table .service_block img {
	vertical-align: middle;
}

table .service_block span {
	width: calc(100% - 60px);
	vertical-align: middle;
	display: inline-block;
}

.min_height_100 {
	min-height: calc(100vh - 220px);
}

.small_title {
	color: #4a5271;
	font-size: 26px;
	margin-bottom: 30px;
	margin-top: 10px;
}

.service_list .service_block {
	border-top: 1px solid rgba(188, 188, 203, 0.37);
	padding: 15px 0;
}

.date_icon {
	position: relative;
}

.date_icon input {
	padding-right: 30px;
}

.date_icon img {
	position: absolute;
	right: 15px;
	top: 15px;
}

.add_service_block {
	margin-bottom: 15px;
	width: 100%;
	display: inline-block;
}

.invoice_box {
	/* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06); */
	background: #fff;
	border-radius: 20px;
	overflow: hidden;
}

.invoice_box .invoice_header {
	/* border-bottom: 1px solid #dee2e6; */
	padding: 30px;
}

.invoice_box p {
	line-height: 24px;
}

.invoice_box .invoice_header .company_logo img {
	max-height: 180px;
}

.invoice_box .invoice_header .price_text {
	margin-top: 30px;
	color: #31cce5;
	font-size: 28px;
}

.invoice_box .invoice_header .price_text span {
	font-size: 20px;
}

.invoice_box .invoice_body .invoice_due p .q {
	width: calc(100% - 150px);
	display: inline-block;
}

.invoice_box .invoice_body .invoice_due p .a {
	width: 140px;
	display: inline-block;
}

.invoice_box .detail_sec {
	padding: 30px;
}

.invoice_box .address_block .address p {
	line-height: 35px;
}

.invoice_box .address.center_block {
	width: auto;
	display: table;
	margin: 0 auto;
}

.invoice_box .terms_desc .desc p {
	margin-bottom: 10px;
}

.invoice_box .submit_btn {
	padding: 30px 0px;
}

.payment_tab .nav-tabs .nav-item.show .nav-link,
.payment_tab .nav-tabs .nav-link.active,
.payment_tab .nav-tabs .nav-item .nav-link {
	border: none;
}

.payment_tab .nav-tabs .nav-item {
	margin-bottom: 20px;
}

.payment_tab .nav-tabs .nav-item .nav-link:hover,
.payment_tab .nav-tabs .nav-item .nav-link.active {
	background-color: #eafafd;
	box-shadow: 0px 1px 3px rgba(49, 204, 229, 0.3);
}

.payment_tab .nav-tabs .nav-item .nav-link {
	width: 200px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	height: 100%;
	padding: 40px 15px;
	margin-right: 30px;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.payment_tab .nav-tabs .nav-item .nav-link .check_circle {
	position: absolute;
	right: 15px;
	top: 15px;
	background: #332ca2;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	display: none;
}

.payment_tab .nav-tabs .nav-item .nav-link.active .check_circle {
	display: block;
}

.payment_tab .nav-tabs .nav-item .nav-link.active .check_circle:after {
	left: 6px;
	top: 3px;
	width: 7px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	content: '';
	position: absolute;
}

.faq_page .center_feature_sec::after {
	display: none;
}

.cust_dropdown {
	width: 250px;
	max-width: 100%;
}

.page-link {
	color: #332ca2;
}

.page-item.active .page-link {
	background-color: #332ca2;
	border-color: #332ca2;
}

.custom_pagination ul {
	text-align: right;
}

.custom_pagination li.pagination-previous.disabled,
.custom_pagination li.current span,
.custom_pagination li.pagination-next.disabled,
.custom_pagination li a {
	padding: 8px 12px !important;
	margin-left: -1px;
	background-color: #fff;
	border: 1px solid #dee2e6;
	color: #666;
}

/* .custom_pagination li a{padding: 0 !important} */

.custom_pagination li.pagination-next.disabled,
.custom_pagination li.pagination-previous.disabled {
	color: #bbb;
	cursor: not-allowed;
}

.custom_pagination li.pagination-next,
.custom_pagination li.pagination-previous {
	color: #666;
}

.custom_pagination li a:hover {
	background-color: transparent !important;
	color: #332ca2;
}

.custom_pagination li.current span {
	background-color: #332ca2;
	color: #fff;
}

.custom_pagination li a:focus {
	outline: none;
}

.custom_pagination .ngx-pagination .current {
	background-color: transparent;
	padding: 0;
}

/********faq page********/

.faq_questions {
	background: #f5f6f9;
}

.faq_sidebar .sidebar__inner {
	padding: 30px;
	border: 1px solid rgba(111, 124, 124, 0.17);
}

.faq_detail .faq_questions {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	padding-left: 30px;
	margin-top: 20px;
}

.faq_questions .que_ans {
	opacity: 1;
	float: none;
	color: #000;
	text-shadow: none;
	margin-bottom: 15px;
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-radius: 20px;
}

.faq_questions .q {
	cursor: pointer;
	position: relative;
	line-height: normal;
	font-size: 18px;
	font-weight: 500;
	padding: 20px 40px 20px 20px;
	color: #6f7c7c;
}

.faq_questions .open .q {
	border-bottom: 1px solid rgba(111, 124, 124, 0.17);
	color: #000;
}

.faq_questions .a {
	display: none;
	color: #fff;
	font-size: 16px;
	line-height: 25px;
	padding: 20px;
}

.faq_questions .open .a {
	width: 100%;
	display: block;
	box-sizing: border-box;
}

.faq_sidebar .links a {
	width: 100%;
	display: inline-block;
	border-bottom: 1px solid rgba(111, 124, 124, 0.17);
	padding: 20px 15px;
	color: #6f7c7c;
	font-size: 18px;
	font-weight: 500;
}

.faq_sidebar .links a.active {
	color: #6fb0c7;
}

.faq_sidebar .links a:first-child {
	padding-top: 0;
}

.faq_sidebar .links a:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.faq_questions .circle {
	position: absolute;
	width: 25px;
	height: 25px;
	border-radius: 4px;
	right: 12px;
	top: 20px;
}

.faq_questions .circle .horizontal {
	position: absolute;
	background-color: #6f7c7c;
	width: 15px;
	height: 1.5px;
	left: 50%;
	margin-left: -7px;
	top: 50%;
	margin-top: -2px;
}

.faq_questions .close_que .horizontal {
	transition: all 0.5s ease-in-out;
	transform: rotate(-90deg);
	opacity: 1;
}

.faq_questions .open .horizontal {
	transition: all 0.5s ease-in-out;
	transform: rotate(90deg);
	opacity: 0;
}

.faq_questions .circle .vertical {
	position: absolute;
	background-color: #6f7c7c;
	width: 1.5px;
	height: 15px;
	left: 50%;
	margin-left: -0px;
	top: 50%;
	margin-top: -8px;
}

.faq_questions .close_que .vertical {
	transition: all 0.5s ease-in-out;
	transform: rotate(-90deg);
}

.faq_questions .open .vertical {
	transition: all 0.5s ease-in-out;
	transform: rotate(90deg);
}

.queary_sec .white-box {
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
	padding: 50px;
	border-radius: 20px;
}

/*********range picker************/

.applyBtn.btn-success {
	background-color: #332ca2;
	border-color: #332ca2;
}

.daterangepicker td,
.daterangepicker th {
	padding: 8px;
	font-size: 14px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
	background-color: #332ca2;
}

.daterangepicker .input-mini {
	height: auto;
	font-size: 15px;
	padding: 2px 6px 0 28px;
}

.tax_rates .tax_rate p,
.tax_rates .tax_rate .btn {
	display: inline-block;
	vertical-align: middle;
}

.edit_btn {
	padding: 6px 5px;
	text-align: center;
	border-radius: 8px;
	font-size: 16px;
	width: 30px;
	display: inline-block;
	height: 30px;
}

.delete_btn {
	color: #e74c3c;
	padding: 6px 5px;
	text-align: center;
	border-radius: 8px;
	font-size: 16px;
	width: 30px;
	display: inline-block;
	height: 30px;
	min-width: 30px;
}

.pricing_calculation .cal_row {
	width: 100%;
	display: inline-block;
	border-bottom: 1px solid #f2f2f2;
	padding: 15px 0;
	text-align: right;
}

.pricing_calculation .cal_row .q {
	width: calc(100% - 150px);
	display: inline-block;
	color: #a1a3b4;
	vertical-align: top;
}

.pricing_calculation .cal_row .a {
	width: 145px;
	text-align: right;
	display: inline-block;
	vertical-align: top;
}

.border-top {
	border-top: 1px solid #f2f2f2 !important;
}

.inline_radio .radioContainer {
	display: inline-block;
	margin: 10px 0;
	margin-right: 30px;
}

.discount_form .selection_box {
	display: flex;
	margin: 0 -10px;
	justify-content: space-between;
}

.discount_form .selection_box p {
	margin: 0 10px 20px;
	text-align: center;
	width: 100%;
	border: 1px solid rgba(188, 188, 203, 0.35);
	border-radius: 8px;
	padding: 8px 5px;
	color: #4a5271;
	cursor: pointer;
}

.discount_form .selection_box p.selected {
	color: #332ca2;
	border: 1px solid #332ca2;
}

.choose_tax .tax_row {
	width: 100%;
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	cursor: pointer;
	border-bottom: 1px solid rgba(188, 188, 203, 0.35);
}

.choose_tax .tax_row:last-child {
	border: none;
}

.choose_tax .tax_row .tax_name {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 40px);
}

.choose_tax .tax_row .selected_check {
	display: inline-block;
	vertical-align: middle;
}

.invoice_body .item_table .heading {
	padding: 15px 30px;
	background-color: #f1f1f1;
	color: #332ca2;
}

.invoice_body .table_body {
	padding: 0 30px;
}

.invoice_body .table_body .table_row {
	border-bottom: 1px solid rgba(188, 188, 203, 0.35);
	padding: 15px 0;
	line-height: 25px;
}

.invoice_body .terms_desc {
	border: 1px solid rgba(188, 188, 203, 0.35);
	padding: 15px;
	border-radius: 25px;
}

.identity_proof img {
	height: 128px;
	border-radius: 25px;
	cursor: pointer;
	width: auto;
	max-width: 100%;
	object-fit: cover;
	display: inline-block;
	margin-right: 10px;
}

.grant_access_row {
	padding: 15px 0;
	border-bottom: 1px solid #eaeaea;
}

.grant_access .service_box {
	width: 100%;
	display: inline-block;
}

.grant_access .service_box .detail {
	width: calc(100% - 75px);
	display: inline-block;
}

.grant_access .service_box .on_off_switch {
	width: 70px;
	display: inline-block;
}

.grant_access_row .service_selection {
	display: none;
}

.grant_access_row .service_selection.active {
	padding-top: 15px;
	display: block;
}

.grant_access_row .service_selection .checkContainer {
	display: inline-block;
	margin-right: 30px;
	margin-bottom: 5px;
}

.service_expertise .selected_check {
	border-radius: 5px;
}

body.postion-fixed {
	overflow: hidden;
}

.added_service_expertise {
	border: 1px solid rgba(188, 188, 203, 0.5);
	border-radius: 6px;
	height: 48px;
	padding: 4px 15px;
	white-space: nowrap;
	position: relative;
	overflow: hidden;
}

.added_service_expertise img {
	display: inline-block;
	line-height: normal;
	width: 28px;
	padding-top: 4px;
	margin-right: 5px;
}

.identity_proof .uploaded_img {
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.identity_proof .uploaded_img .close_icon {
	cursor: pointer;
	border-radius: 100%;
	background-color: red;
	width: 20px;
	height: 20px;
	text-align: center;
	font-size: 25px;
	color: #fff;
	position: absolute;
	top: -2px;
	right: -2px;
	line-height: normal;
}

.staff_detail_page .identity_proof img {
	border-radius: 4px;
}

.service_expertise_card .service_box {
	padding: 10px 0;
	border-bottom: 1px solid #eaeaea;
	border-radius: 0;
}

.completed_jobs .cust_user_block {
	width: calc(100% - 150px);
}

.completed_jobs .date_time {
	display: inline-block;
	width: 145px;
	vertical-align: middle;
	text-align: right;
}

.completed_jobs .cust_user_block .user_img img {
	border-radius: 8px;
}

.zoom_img {
	text-align: center;
}

.zoom_img img {
	max-width: 100%;
	max-height: 90vh;
}

.zoom_img .close {
	position: absolute;
	top: -10px;
	right: -10px;
	background: #fff;
	padding: 8px;
	border-radius: 100%;
	box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
	width: 30px;
	height: 30px;
}

.zoom_img .close:hover {
	opacity: 1;
}

.header_navbar ul li.active a {
	color: #332ca2;
}

.transaction_header {
	width: 100%;
	display: inline-block;
	padding: 20px;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	margin: 20px 0;
}

.transaction_header .card_title {
	float: left;
	padding-top: 12px;
}

.transaction_header .left_part {
	float: right;
}

.transaction_header .filter_dates,
.transaction_header .filter_days,
.transaction_header .date_icon {
	display: inline-block;
}

.transaction_header .date_icon {
	width: 160px;
	margin-left: 10px;
}

.transaction_header .filter_days a {
	color: #3d505a;
	display: inline-block;
	padding: 4px 10px 3px;
	margin: 0 4px !important;
	border-radius: 15px;
	border: 1px solid transparent;
	line-height: normal;
}

.transaction_header .filter_days a.active,
.transaction_header .filter_days a:hover {
	border: 1px solid #332ca2;
	color: #332ca2;
}

.transaction_list .list_title {
	background-color: #31cce5;
	padding: 15px 15px 12px;
}

.transaction_list .list_title p {
	color: #fff;
	display: inline-block;
	width: auto;
	line-height: normal;
	font-size: 22px;
}

.transaction_list .list_title .amount {
	float: right;
}

/*A css start*/

/*td.actions {text-align: center;}*/

td.actions a.edit_btn,
td.actions button.delete_btn {
	width: 32px;
	height: 32px;
	border-radius: 5px;
}

.sticky {
	position: sticky;
	top: 80px;
	background: #fff;
	border: 1px solid #eaeaea;
	z-index: 11111;
}

/*datetimepicker*/

.bootstrap-datetimepicker-widget.dropdown-menu {
	display: block;
	margin: 2px 0;
	padding: 4px;
	width: auto;
}

label.control_label.pro {
	padding-top: 5px;
}

.dollar_title {
	font-size: 20px;
	color: #4a5271;
	font-weight: bold;
	line-height: 41px;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid #b8c4d6;
}

span.p-text,
span.right {
	color: #a1a3b4;
	line-height: normal;
	font-size: 15px;
}

span.p-text {
	display: flex;
	flex: 1;
}

.descr-right {
	text-align: right;
}

.form-inline.selectn .form-group {
	/* margin: 0 1%; */
	margin-top: 5px;
	margin-bottom: 11px;
	margin-left: 15px;
	margin-right: 5px;
}

.cust-drop {
	width: 185px;
	max-width: 100%;
}

.cust-drop2 {
	width: 185px;
	max-width: 100%;
}

.top-bar .date_icon img {
	position: absolute;
	right: 0;
	top: 15px;
	left: 15px;
}

.color {
	color: #263238;
}

.bold-texts p {
	font-size: 20px;
	/* color: .bold-texts; */
	color: #45545f;
	font-weight: bold;
	line-height: 33px;
}

.card_view.height {
	height: 290px;
}

/*select placeholder*/

.form-inline.selectn .form-group span.select2.select2-container span {
	color: #bcbccb;
}

.form-inline.selectn .date_icon img {
	position: absolute;
	right: 0;
	top: 15px;
	left: 15px;
}

.form-inline.selectn .date_icon input {
	padding-left: 50px;
	color: #bcbccb;
}

.form-inline.selectn .date_icon .cust-drop2 img {
	position: absolute;
	right: 0;
	top: 15px;
	left: 15px;
}

.cust-drop2 span.select2-selection.select2-selection--single {
	padding-left: 40px;
}

.dot-img {
	margin: 0 auto;
	position: relative;
	z-index: 10;
}

.user_img1 {
	display: inline-block;
	vertical-align: middle;
	width: 30px;
}

.cust_user_block.big_img .user_detail p.font16 {
	color: #3d505a;
	font-weight: 600;
}

.button-group button p {
	font-size: 14px;
	color: #0054d9;
	font-weight: 500;
	line-height: 14px;
	margin: 0 0 0 10px;
}

.text {
	padding-left: 16%;
}

.text2 {
	text-align: center;
	padding-right: 15%;
}

.details {
	margin: 0 auto;
	display: block;
	padding: 55px 0;
}

.materials a img {
	vertical-align: middle;
	padding-right: 10px;
}

.table_rows .row {
	border-top: 1px solid rgba(188, 188, 203, 0.35);
	padding: 15px 0;
	line-height: 25px;
}

.iframe-map iframe {
	width: 100%;
	height: 100%;
}

.iframe-map {
	width: 100%;
	height: 100%;
	padding-bottom: 4%;
}

select.select2 {
	border: 1px solid rgba(188, 188, 203, 0.5);
	border-radius: 6px;
	height: auto;
	padding: 10px 15px;
}

/*job detail updated page*/

.user_detail .user-title {
	font-size: 18px;
	color: #45545f;
	font-weight: 600;
	line-height: 22px;
}

.call_detail img {
	margin-right: 7px;
	vertical-align: middle;
}

/*timeline css*/

.table_user_block_new {
	display: flex;
	align-items: center;
}

.tracking-detail {
	padding: 3rem 0;
}

#tracking {
	margin-bottom: 1rem;
}

[class*='tracking-status-'] p {
	margin: 0;
	font-size: 1.1rem;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}

[class*='tracking-status-'] {
	padding: 1.6rem 0;
}

.tracking-status-inforeceived {
	background-color: #214977;
}

.tracking-item {
	border-left: 1px solid #e5e5e5;
	position: relative;
	padding: 0 0 0 2.5rem;
	font-size: 16px;
	margin-left: 2rem;
	min-height: 7rem;
	color: #4a5271;
}

.call_detail.black-text p {
	color: #45545f;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

.tracking-item label.label.bold {
	vertical-align: -webkit-baseline-middle;
	font-size: 18px;
}

.tracking-item:last-child {
	border: 0;
}

.tracking-item .tracking-icon {
	position: absolute;
	left: -1.6rem;
	width: 50px;
	height: 50px;
}

.tracking-item .tracking-icon.status-sponsored {
	background-color: #f68;
}

.tracking-item .tracking-icon.status-delivered {
	background-color: #4cbb87;
}

.tracking-item .tracking-icon.status-outfordelivery {
	background-color: #f5a551;
}

.tracking-item .tracking-icon.status-deliveryoffice {
	background-color: #f7dc6f;
}

.tracking-item .tracking-icon.status-attemptfail {
	background-color: #b789c7;
}

.tracking-item .tracking-icon.status-exception {
	background-color: #d26759;
}

.tracking-item .tracking-icon.status-inforeceived {
	background-color: #214977;
}

.eq-height {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.above_border {
	border-top: 1px solid #eaeaea;
	padding-top: 20px;
}

.assign_staff {
	text-align: center;
	padding-top: 5%;
}

.call_detail p {
	margin: 5px 0;
}

.job_detail_page.dotted.content_padding .row {
	margin-bottom: 26px;
}

.crd-heading {
	display: flex;
	flex: 1;
}

.card-titles {
	display: flex;
}

.deatil_row .right-side {
	float: right;
}

.d-f {
	display: flex;
	flex: 1;
}

.bordes.d-flex {
	border-top: 1px solid rgba(188, 188, 203, 0.35);
	padding: 15px 0;
	line-height: 25px;
	/*border-bottom: 1px solid rgba(188,188,203,0.35);*/
}

/*dashboard updated page*/

.font14 {
	font-size: 14px;
	line-height: 22px;
}

.font13 {
	font-size: 13px;
	line-height: 17px;
}

.font-20 {
	font-size: 20px;
}

.inline-block {
	display: -webkit-inline-box;
}

.flex-1 {
	flex: 1;
}

.flex-6 {
	flex: 6;
}

.right_info {
	float: right;
}

.verification_text {
	min-height: 50px;
}

.verification_text > span {
	position: absolute;
	left: 0;
	padding-top: 10px;
}

.spinner-class {
	position: absolute;
	height: 100px;
	width: 100px;
	top: 50%;
	left: 50%;
	margin-left: -50px;
	margin-top: -50px;
	background-size: 100%;
}

.choose_service_sec.choose_service_box {
	display: flex;
	max-height: calc(100vh - 255px);
	flex-wrap: wrap;
	overflow: auto;
}

.choose_service_sec.choose_service_box .service_box {
	width: 50%;
}

@media (min-width: 992px) {
	.tracking-item .tracking-date {
		position: absolute;
		left: -10rem;
		width: 7.5rem;
		text-align: right;
	}
	.tracking-item .tracking-date span {
		display: block;
	}
	.tracking-item .tracking-content {
		padding: 0;
		background-color: transparent;
	}
}

@media (max-width: 1680px) {
}

@media (max-width: 1600px) {
	.custom-container,
	.navbar > .container-fluid {
		width: 90%;
	}
}

@media (max-width: 1440px) {
	.button-div button {
		position: absolute;
		bottom: 65%;
		left: 77% !important;
		right: 0;
	}
	h3 {
		font-size: 34px;
		line-height: 38px;
	}
	h2 {
		font-size: 40px;
	}
	.about_detail .service_detail {
		margin-left: 50px;
	}
}

@media (max-width: 1280px) {
	.progress-tracker .progress-step .progress-marker {
		width: auto !important;
	}
}

@media (max-width: 1366px) {
	.page_header .right_part_btns input {
		width: 400px;
		padding: 6px 15px;
	}
	.progress-step .progress-marker label {
		width: 150px;
		font-size: 16px;
	}
	.user_control_sec.tax_rates .control_row .detail {
		width: calc(100% - 40%);
	}
	.g-map iframe {
		width: 100%;
		height: 250px;
		border-radius: 15px;
	}
}

@media (max-width: 1199px) {
	h3 {
		font-size: 28px;
		line-height: 35px;
	}
	h5 {
		font-size: 24px;
		margin-bottom: 10px;
		line-height: 30px;
	}
	.custom-container,
	.navbar > .container-fluid {
		width: 100%;
	}
	.pb-80 {
		padding-bottom: 60px;
	}
	.pt-80 {
		padding-top: 60px;
	}
	.landing_sec .banner {
		height: 500px;
	}
	.progress-step .progress-marker label {
		width: 120px;
	}
	.progress-step .progress-marker label span {
		font-size: 12px;
	}
	.payment_tab .nav-tabs .nav-item .nav-link {
		width: 170px;
		padding: 30px 15px;
	}
	.form-control {
		padding: 10px;
	}
	.g-map iframe {
		width: 100%;
		height: auto;
		border-radius: 15px;
	}
}

@media (max-width: 991px) {
	.buttons-group {
		display: block !important;
	}
	.g-map iframe {
		width: 100%;
		height: auto;
	}
	.submit_btn {
		align-items: center;
		display: flex;
		width: 100%;
		justify-content: center;
	}
	.progress-tracker {
		display: flex;
		flex: 1 1 0%;
		margin-top: 15px;
		justify-content: center;
		/* align-items: center; */
	}
	.page_header .right_part_btns input {
		width: 277px;
		padding: 6px 15px;
	}
	.about_detail .content {
		width: 90%;
	}
	.about_detail .bg_img {
		width: 100%;
		height: 240px;
	}
	.email_sec .custom-container {
		width: 100%;
	}
	.footer_top .column1 .pr-5 {
		padding: 0 !important;
	}
	header .navbar-nav .nav-link {
		padding: 4px 8px !important;
	}
	header .nav-link.header_btn {
		margin: 0 0px 0 10px;
	}
	.navbar-brand img {
		max-width: 180px;
	}
	.modal-dialog.modal-lg {
		width: 90%;
		min-width: 90%;
	}
	.job_status_list .cust_user_block {
		margin-bottom: 5px;
	}
	.dashboard_page .right_part_btns .btn {
		margin: 0 0 10px !important;
	}
	.page_header .right_part_btns {
		margin-top: 20px;
	}
	.progress-step .progress-marker .dot {
		width: 30px;
		height: 30px;
	}
	.progress-step .progress-marker label {
		width: 85px;
		font-size: 12px;
	}
	.progress-step .progress-marker label span {
		font-size: 12px;
		line-height: 16px;
		margin-top: 2px;
		display: block;
	}
	/*.progress-step.is-active .progress-marker .dot:before, .progress-step.is-complete .progress-marker .dot:before {top: 4px;width: 9px;  height: 16px;border-width: 0 2px 2px 0;}*/
	.progress-step .progress-marker::after {
		top: 15px;
		right: -52px;
	}
	.cust_info_sec.account_info .que_ans .q {
		width: 200px;
	}
	.cust_info_sec.account_info .que_ans .a {
		width: calc(100% - 205px);
	}
	.user_control_sec.tax_rates .control_row .detail {
		width: calc(100% - 150px);
	}
	.branches_sec {
		margin-bottom: 20px;
	}
	.branches_sec.border-right {
		border: none !important;
	}
	.blog_box .img {
		height: 210px;
	}
	.blog_box .desc p {
		line-height: 22px;
		font-size: 14px;
	}
	.small_title {
		font-size: 24px;
		margin-bottom: 20px;
		margin-top: 5px;
	}
	.invoice_box .address_block .address p {
		line-height: 30px;
	}
	.payment_tab .nav-tabs .nav-item .nav-link {
		width: 115px;
		padding: 15px 15px;
	}
	.payment_tab .nav-tabs .icon img {
		width: 50px;
	}
	.payment_tab .nav-tabs .title {
		margin: 10px 0 0;
		font-size: 16px;
	}
	.payment_tab .nav-tabs .nav-item .nav-link .check_circle {
		right: 10px;
		top: 10px;
		height: 18px;
		width: 18px;
	}
	.payment_tab .nav-tabs .nav-item .nav-link.active .check_circle:after {
		width: 6px;
		height: 10px;
	}
	.staff_detail_page .border-left {
		border: none !important;
		margin-top: 15px;
	}
	.transaction_header .date_icon {
		margin-left: 0;
		margin-right: 5px;
	}
	.transaction_header .filter_days {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.transaction_header .filter_dates,
	.transaction_header .filter_days,
	.transaction_header .card_title {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.progress-tracker .progress-step .progress-marker {
		width: auto;
	}
	.setting_page .upload_user_photo {
		float: none !important;
	}
	.pd-checkbox input + label:before {
		left: 340px !important;
	}
	.g-map iframe {
		width: 100%;
	}
	.right_info {
		float: none;
	}
	.cust-drop,
	.cust-drop2 {
		width: 100%;
		max-width: 100%;
	}
	.row.bottom .text {
		padding: 20px;
		text-align: center;
	}
	.row.bottom .text2 {
		padding-right: 0;
	}
	.row.bottom .button-group button {
		display: block;
		margin: 0 auto;
	}
	.eq-height {
		height: auto;
	}
	.descr-right {
		text-align: justify;
		padding-top: 5%;
	}
	.submit_btn.text-right.mt-2 {
		text-align: center !important;
	}
	.deatil_row .right-side {
		float: none;
		text-align: center;
	}
	.form-inline.selectn {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.cust_user_block.big_img {
		text-align: justify;
		padding: 20px;
	}
	.form-inline.selectn .form-group {
		margin: 0;
		padding: 5px 0;
		width: 100%;
	}
	.descr-right {
		padding: 0;
		margin-top: 7%;
	}
	.assign_staff {
		text-align: center;
		padding-top: 0;
	}
	.navbar-toggler {
		padding: 0;
	}
	.navbar-toggler:focus {
		outline: none;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(111,124,124, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
	header .navbar-nav .nav-item {
		align-self: initial;
	}
	header .nav-link.header_btn,
	header .nav-link.signup_btn {
		margin: 5px 0;
		width: 90px;
		text-align: center;
	}
	h1 {
		font-size: 34px;
		line-height: 45px;
	}
	.landing_sec .banner {
		height: 400px;
	}
	.landing_sec .content p {
		padding: 0 !important;
	}
	.feature_sec .feature_listing .sec_margin {
		margin-top: 0;
	}
	.flex-mobile {
		display: flex;
		flex-direction: column-reverse;
	}
	.about_detail.pt-5 {
		padding: 0 !important;
	}
	.about_detail .bg_img {
		width: 90%;
		height: 200px;
	}
	.about_detail .bg_img:after {
		transform: translate(-30px, -30px);
	}
	.about_detail .content {
		width: 100%;
		margin-top: 30px;
	}
	.about_detail .margin_bottom {
		margin-bottom: 40px;
	}
	.about_detail .bg_img.left_img {
		margin-left: 30px;
	}
	.about_detail .service_detail {
		margin-left: 0;
	}
	.join_sec .btn,
	.email_sec .btn {
		float: left;
		margin-top: 25px;
	}
	.screenshot_sec .bg_img.full_img {
		margin-top: 30px;
	}
	.info_box {
		margin: 15px 0;
	}
	.info_box .desc {
		width: calc(100% - 70px);
	}
	.part_of_service .content {
		padding: 20px 30px 0;
	}
	footer .column1 {
		margin-bottom: 20px;
	}
	footer h5 {
		margin-bottom: 15px;
	}
	.pb-5 {
		padding-bottom: 30px !important;
	}
	.pt-5 {
		padding-top: 30px !important;
	}
	footer .footer_bottom {
		padding: 15px 0;
	}
	h3 {
		font-size: 22px;
		line-height: 30px;
	}
	.custom-container {
		padding: 0 15px;
	}
	h2 {
		font-size: 28px;
		margin-bottom: 15px;
	}
	.modal_padding {
		padding: 20px 20px;
	}
	.modal .head h2 {
		font-size: 24px;
		line-height: 30px;
	}
	.modal .close {
		top: 38px;
		width: 18px;
	}
	.signup_card {
		padding: 25px 0;
	}
	.signup_card .card_box {
		margin-bottom: 30px;
		height: auto;
	}
	.modal-dialog.modal-lg {
		width: 95%;
		min-width: 95%;
		margin: 15px auto;
	}
	.verifiction_block {
		width: 100%;
	}
	.verifiction_block .row {
		margin: 0 -8px;
	}
	.verifiction_block .col-2 {
		padding: 0 8px;
	}
	.modal {
		overflow-y: auto;
	}
	.information {
		padding-left: 15%;
	}
	.page_header .nav-tabs .nav-link {
		font-size: 16px;
		margin: 0 8px;
	}
	.custom_tab .nav-tabs .nav-link {
		margin: 0 10px;
	}
	.page_header .right_part_btns input,
	.page_header .right_part_btns .search_box {
		width: 100%;
	}
	.content_padding {
		padding: 15px;
	}
	.admin_heading {
		font-size: 26px;
		margin-top: 15px;
	}
	.sidebar {
		width: 55px;
	}
	#content {
		width: 100%;
		padding-left: 55px;
		padding-top: 70px;
	}
	.header_navbar .user_block img {
		width: 35px;
		height: 35px;
		vertical-align: top;
	}
	.header_navbar .user_block .user_detail .name {
		font-size: 16px;
		line-height: normal;
	}
	.header_navbar ul li a i {
		font-size: 25px;
	}
	header.after_login_header nav,
	.after_login_header {
		height: 65px;
	}
	.head_calender input {
		font-size: 26px;
		width: 160px;
	}
	.right_part_btns .btn-group .btn {
		min-width: 95px;
	}
	.head_calender {
		margin-bottom: 20px;
	}
	.total_job_sec .detail_box.text-right {
		text-align: left !important;
	}
	.total_job_sec .detail_box.text-right .card_heading {
		margin: 10px 0 0;
	}
	.card_view {
		padding: 15px;
	}
	.table thead th {
		font-size: 16px;
	}
	.table td {
		font-size: 14px;
	}
	.card_view_header_block {
		display: inline-block;
		text-align: center;
	}
	.card_view_header_block .search_box {
		margin: 0 0 10px !important;
	}
	.table td,
	.table th {
		padding: 10px;
	}
	.progress-tracker {
		width: 100%;
		white-space: nowrap;
		overflow-y: auto;
	}
	.progress-tracker .progress-step {
		margin-bottom: 10px;
	}
	.progress-step .progress-marker label {
		width: 140px;
	}
	.progress-step .progress-marker::after {
		right: -60px;
	}
	.view_invoice_modal p {
		font-size: 14px;
	}
	.view_invoice_modal .card_title {
		font-size: 16px;
	}
	.cust_user_block.big_img .user_img img {
		width: 50px;
		height: 50px;
	}
	.cust_user_block.big_img .user_detail {
		width: calc(100% - 70px);
	}
	.job_detail_page .cust_user_block {
		margin-bottom: 10px;
	}
	.job_detail_page .address {
		margin-bottom: 5px;
	}
	form .custom_radio label {
		min-width: auto;
	}
	.cust_info_sec .que_ans .q,
	.cust_info_sec .que_ans .a,
	.cust_info_sec.account_info .que_ans .q,
	.cust_info_sec.account_info .que_ans .a {
		width: 100%;
	}
	.cust_info_sec .user_block {
		width: 100%;
	}
	.cust_info_sec .inline_div {
		margin-top: 10px;
	}
	.cust_info_sec .inline_div.job_no:after {
		display: none;
	}
	.scroll_tab .nav {
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;
		display: inline-block;
	}
	.scroll_tab .nav li {
		display: inline-block;
		margin-bottom: 5px;
	}
	.page_header.scroll_tab .right_part_btns {
		margin-top: 10px;
	}
	.page_header.scroll_tab .right_part_btns .btn {
		margin-bottom: 10px;
		margin-right: 0 !important;
	}
	.tax_rates .control_row .tax_amount {
		vertical-align: top;
		margin-top: 20px;
	}
	.cust_info_sec {
		width: 100%;
		display: inline-block;
	}
	.branches_sec .user_block img {
		margin-right: 10px;
		width: 50px;
		height: 50px;
	}
	.branches_sec .user_block .user_detail {
		width: calc(100% - 65px);
	}
	.branches_sec .user_block img,
	.branches_sec .user_block .user_detail {
		vertical-align: top;
	}
	.small_title {
		font-size: 20px;
	}
	.invoice_box .address_block .address {
		width: 100%;
		margin-bottom: 15px;
		text-align: left !important;
		display: inline-block;
	}
	.invoice_box .invoice_header,
	.invoice_box .detail_sec,
	.invoice_box .submit_btn {
		padding: 15px;
	}
	.page-link {
		font-size: 14px;
		padding: 0.5rem 0.5rem;
	}
	.invoice_box .invoice_body .invoice_due p .q {
		width: calc(100% - 112px);
	}
	.invoice_box .invoice_body .invoice_due p .a {
		width: 110px;
	}
}

@media (max-width: 575px) {
	.button-div button {
		position: absolute;
		bottom: 20% !important;
		left: 5% !important;
		right: 0;
	}
	.landing_sec .banner {
		height: auto;
	}
	h1 {
		font-size: 30px;
		line-height: 40px;
	}
	.branch_row .branch_detail {
		width: 100%;
	}
	.branch_action_btn {
		width: 100%;
		margin-top: 10px;
	}
	.job_status_list .btn-gray-stroke {
		margin-left: 70px;
		float: left !important;
		margin-top: 10px;
	}
	.font18 {
		font-size: 16px;
		line-height: 25px;
	}
	.paid_jobs_list .cust_user_block,
	.staff_status_list .cust_user_block {
		width: 100%;
	}
	.paid_jobs_list .job_price,
	.staff_status_list .status_div {
		width: 100%;
		text-align: right;
		margin-top: 3px;
	}
	.job_detail_page .total_amount {
		margin-bottom: 10px;
	}
	.cust_info_sec .que_ans {
		margin-top: 20px;
	}
	.cust_info_sec .user_block .user_img img {
		width: 60px;
		height: 60px;
	}
	.cust_info_sec .user_block .user_detail {
		width: calc(100% - 80px);
	}
	.user_control_sec .control_row .detail {
		width: calc(100% - 71px);
	}
	.setting_page .card_title a {
		margin-top: 5px;
		margin-left: 5px;
	}
	.user_control_sec.tax_rates .control_row .detail {
		width: calc(100% - 115px);
	}
	.service_list .service_block .btn {
		margin-top: 5px;
	}
	.invoice_box .invoice_header .text-right {
		text-align: left !important;
	}
	.invoice_box .invoice_header .price_text {
		margin-top: 15px;
	}
	.invoice_box p {
		font-size: 12px;
		line-height: 20px;
	}
	.invoice_box .title {
		font-size: 18px;
	}
	.invoice_box .address_block .address p {
		line-height: 25px;
	}
	.invoice_box .submit_btn .btn {
		width: 100%;
		margin-bottom: 5px;
	}
	.btn {
		min-width: auto !important;
		margin-bottom: 5px;
	}
	.tax_rates .control_row .text-center,
	.tax_rates .control_row .text-right {
		text-align: left !important;
	}
	.setting_page .tab-content .card_title {
		width: 100%;
		display: inline-block;
		margin-bottom: 10px !important;
	}
	.invoice_box .invoice_due {
		border-top: 1px solid rgba(188, 188, 203, 0.35);
		margin-top: 15px;
		padding-top: 15px;
	}
	.invoice_body .item_table .heading {
		padding: 15px;
		font-size: 12px;
	}
	.invoice_body .item_table .table_row {
		font-size: 12px;
		line-height: 20px;
		padding: 10px 0;
	}
	.invoice_body .table_body {
		padding: 0 15px;
	}
	.invoice_box .submit_btn .btn {
		font-size: 14px;
	}
	.completed_jobs .date_time {
		width: 100%;
		padding-left: 70px;
		text-align: left;
	}
	.completed_jobs .cust_user_block {
		width: 100%;
	}
	.grant_access_row .service_selection .checkContainer {
		margin-right: 0;
		margin-bottom: 10px;
		width: 49%;
	}
	.grant_access .service_box .detail {
		vertical-align: top;
	}
}

@media (max-width: 480px) {
	.cust_info_sec .user_block .user_img img {
		margin: 0;
	}
	.cust_info_sec .user_block .user_detail {
		width: 100%;
	}
	.cust_info_sec .single_row_info {
		text-align: center;
		margin-bottom: 0;
	}
	.cust_info_sec .inline_div {
		margin: 0;
		padding: 0 8px;
	}
	.cust_info_sec .user_block {
		display: table;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
	.cust_info_sec .inline_div:after {
		left: -3px;
	}
	.cust_info_sec .inline_div p {
		font-size: 14px;
	}
	.cust_info_sec .user_block .user_detail {
		width: auto;
		padding-left: 15px;
	}
	.pricing_calculation .cal_row .q {
		width: calc(100% - 95px);
	}
	.pricing_calculation .cal_row .a {
		width: 90px;
	}
	.discount_form .selection_box p {
		margin: 0 5px 15px;
		font-size: 14px;
	}
	.discount_form .selection_box {
		margin: 0 -5px;
	}
	.transaction_header .date_icon {
		width: 100%;
		margin-bottom: 5px;
	}
	.transaction_header .filter_days a {
		padding: 4px 8px 3px;
	}
}

@media (max-width: 375px) {
	.modal_padding {
		padding: 20px 0;
	}
	.modal .head h2 {
		font-size: 20px;
	}
	.modal .close {
		top: 15px;
		width: 15px;
		right: 15px;
	}
	.btn-sm {
		font-size: 14px;
	}
	/*    .btn{max-width: 100% !important;}*/
	.modal .btn {
		min-width: 100% !important;
	}
	.screenshot_sec .bg_img .btn {
		min-width: calc(100% - 40px) !important;
	}
}

@media (max-width: 360px) {
	.page-link {
		font-size: 12px;
		padding: 0.3rem 0.45rem;
	}
}

/*custom ratings*/

span.stars i {
	margin: 5px;
}

span.stars {
	font-size: 21px;
}

.short-desc {
	color: #4a5271;
	line-height: 21px;
	font-size: 15px;
	text-align: justify;
	padding: 5px 20px;
	margin-top: 20px;
}

.mini_height {
	max-height: 400px;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.mini_height label.checkContainer {
	margin-top: 6px;
}

.buttons-group {
	display: flex;
}

/* hide the regular checkbox */

.pd-checkbox input {
	opacity: 0;
	position: absolute;
}

/* position the label */

.pd-checkbox input,
.pd-checkbox label {
	display: inline-block;
	vertical-align: middle;
	margin: 5px;
	cursor: pointer;
}

.pd-checkbox label {
	position: relative;
	width: 100%;
}

/* style the unchecked checkbox */

.pd-checkbox input + label:before {
	content: '';
	background: #fff;
	border: 2px solid #ddd;
	display: inline-block;
	vertical-align: middle;
	width: 18px;
	height: 18px;
	padding: 5px;
	margin-right: 10px;
	text-align: center;
	font-size: 20px;
	line-height: 18px;
	position: absolute;
	left: 420px;
	right: 0;
	border: 0;
}

/* style the checked checkbox */

.pd-checkbox input:checked + label:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	background: #fff;
	color: #0054d9;
}

p.form-field {
	color: #3d505a;
	font-size: 18px;
}

p.form-field.checkbox1,
p.form-field.checkbox2,
p.form-field.checkbox3 {
	border-bottom: 1px solid #707070;
	padding: 10px 0;
}

.gray_text {
	color: #9daabf;
}

.pd-checkbox label img {
	float: left;
	margin-right: 11px;
}

.color_gray {
	color: #45545f;
}

form#select-admin .selected_check {
	float: right;
	vertical-align: middle !important;
	margin-top: 7px;
}

#select-admin ul li {
	color: #aaaaaa;
	display: block;
	position: relative;
	float: left;
	width: 100%;
	height: 100px;
	border-bottom: 1px solid #707070;
}

#select-admin ul li input[type='radio'] {
	position: absolute;
	visibility: hidden;
}

#select-admin ul li label {
	display: block;
	position: relative;
	font-weight: 300;
	font-size: 1.35em;
	padding: 25px 0;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
	width: 100%;
}

#select-admin ul li:hover label {
	color: #ffffff;
}

#select-admin ul li .check {
	display: block;
	position: absolute;
	border: 2px solid #332ca2;
	border-radius: 100%;
	height: 25px;
	width: 25px;
	top: 45px;
	right: 0;
	left: auto;
	z-index: 5;
	transition: border 0.25s linear;
	-webkit-transition: border 0.25s linear;
}

#select-admin ul li:hover .check {
	border: 5px solid #332ca2;
}

#select-admin ul li .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 10px;
	width: 10px;
	top: 5px;
	left: 6px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

#select-admin input[type='radio']:checked ~ .check {
	border: 2px solid #332ca2;
}

#select-admin input[type='radio']:checked ~ .check::before {
	background: #332ca2;
}

#select-admin input[type='radio']:checked ~ label {
	color: #332ca2;
}

#select-admin .signature {
	margin: 10px auto;
	padding: 10px 0;
	width: 100%;
}

.group-label {
	width: 100%;
	display: flex;
}

.float-icon {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #332ca2;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
}

a.float-icon:hover {
	color: #fff;
}

i.fa.fa-plus {
	margin-top: 5px;
}

.filters-group .form-inline .form-control {
	max-width: 185px;
}

.btn.btn-outline-secondary {
	border: 1px solid #0054d9;
}

.btn.btn-outline-secondary p {
	padding-top: 5px;
	color: #0054d9;
	padding-left: 10px;
}

.button-div {
	position: relative;
}

.button-div button {
	position: absolute;
	bottom: 65%;
	left: 89%;
	right: 0;
}

.call-mail img {
	width: 30px;
	margin-top: 10px;
	margin-right: 5px;
}

.table-bordered td,
.table-bordered th {
	border: 0px;
}

.table-responsive > .table-bordered {
	border: 1px solid #e1e1e1;
}

body {
	background: #ffffff;
}

.social_media {
	width: 100%;
	text-align: center;
	display: inline-block;
}

.fb_btn {
	background: #3b5998;
	color: #fff;
	/*margin: 10px;*/
	margin-bottom: 15px;

	box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.fb_btn:hover {
	background: #38538d;
	border: 1px solid #315bab;
	color: #fff;
}
.fb_btn i.icon {
	background: url('../images/facebook.svg') no-repeat center center;
	background-size: 9px;
	width: 18px;
	height: 18px;
	display: inline-block;
	margin-right: 5px;
	vertical-align: -0.25em;
}
.google_btn {
	background: #fff;
	color: #000;
	/*margin: 10px;*/

	box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.google_btn:hover {
	background: #fff;
	border: 1px solid #fff;
	color: #000;
}
.google_btn i.icon {
	background: url('../images/google.svg') no-repeat center center;
	background-size: 18px;
	width: 18px;
	height: 18px;
	display: inline-block;
	margin-right: 5px;
	vertical-align: -0.25em;
}

.autocomplete-dropdown-container {
	background-color: #fff;
	position: absolute;
	top: calc(70% + 5px);
	width: 100%;
	z-index: 1111;
}

.autocomplete-dropdown-container > div {
	width: 100%;
	padding: 5px 10px;
	border: 1px solid transparent;
}

.autocomplete-dropdown-container > div:hover {
	background: #eeeeee;
}

.autocomplete-dropdown-container > div:first-child {
	margin-top: 10px;
}

.autocomplete-dropdown-container > div:last-child {
	margin-bottom: 10px;
}

input.location-search-input[aria-expanded='true']
	~ .autocomplete-dropdown-container {
	border-radius: 5px;
	box-shadow: 0 0 3px 3px #f5f5f5;
}

input.location-search-input {
	padding-right: 30px;
}

.react-tel-input > input.select2.form-control {
	width: calc(100% + 5px) !important;
	height: 44px;
	padding-right: 2px;
	padding-left: 40px;
	text-align: center;
}

.react-tel-input .flag-dropdown {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.text-align-center {
	text-align: center !important;
}

.dashboard-row .dashboard-cards {
	height: 465px;
	overflow: auto;
}

.login-page {
	height: 100vh;
	align-items: center;
	justify-content: center;
}

.border-none {
	border: none !important;
}

.code {
	width: 10%;
	display: inline-block;
	margin-right: 9px;
}

.add_new_customer_form_title {
	font-weight: 700;
	font-size: 18px;
	margin: 15px 0px;
}

.customer-checkbox {
	width: auto !important;
	display: inline-block !important;
	margin-right: 10px;
}

.card-footer div {
	display: flex;
	float: right;
	margin-bottom: 15px;
}

.cust-name,
.link-text {
	cursor: pointer;
	color: #0054d9;
}

.table-header {
	font-size: 18px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
	border: none !important;
}
/****login****/
.login {
	height: 100vh;
}
.login .form {
	width: 400px;
}
.header-logo {
	width: 180px;
}
.login h4 {
	font-size: 1.7rem;
	font-weight: 600;
}
.updates-wrapper {
	background: url('../images/lg-bg.svg') no-repeat right bottom;
	background-size: cover;
	/* align-items: center; */
	display: flex;
	justify-content: center;
}
.updates-wrapper .logo-bar {
	display: flex;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	/* align-items: center; */
}
.logo-bar {
	width: 100%;
}
.logo-bar .custom-logo {
	width: 300px;
}
/*input*/
label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
.form-control {
	font-size: 14px;
}
.form-control:focus {
	box-shadow: none !important;
	border: 1px solid rgba(188, 188, 203, 0.5) !important;
}
.form-check-input {
	position: relative !important;
}
.custom-select:focus {
	box-shadow: none !important;
	border: 1px solid rgba(188, 188, 203, 0.5) !important;
}
input,
button {
	outline: none !important;
}
.react-tel-input > input.select2.form-control {
	height: 38px;
}
.register_phone .react-tel-input > input.select2.form-control {
	min-height: 40px !important;
	border-radius: 6px !important;
}
.register_phone .react-tel-input .selected-flag,
.register_phone .react-tel-input .flag-dropdown {
	border-radius: 6px 0 0 6px !important;
}
/*button*/
.btn-primary,
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary:disabled {
	background-color: #0054d9 !important;
	border: 2px solid #0054d9 !important;
	box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
	color: #fff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #0054d9 !important;
	color: #fff !important;
	box-shadow: none;
	border: 2px solid #0054d9 !important;
}
.btn-secondary,
.btn-secondary.disabled,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary:disabled {
	background-color: #3d4f59 !important;
	border: 2px solid #3d4f59 !important;
	box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
	color: #fff !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
	background-color: #3d4f59 !important;
	color: #fff !important;
	box-shadow: none;
	border: 2px solid #3d4f59 !important;
}
.btn-link {
	font-size: 16px;
}
.c-app:not(.c-legacy-theme)
	.btn-group
	> .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent'])::before,
.c-app:not(.c-legacy-theme)
	.btn-group-vertical
	> .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent'])::before {
	box-shadow: none !important;
}
.btn-sm {
	font-size: 12px !important;
	min-width: auto;
}
.btn.btn-outline-primary {
	border-color: #0054d9 !important;
	color: #0054d9 !important;
}
.btn.btn-outline-primary:hover {
	border-color: #0054d9 !important;
	color: #fff !important;
	background-color: #0054d9 !important;
}
.btn.btn-outline-secondary {
	border-color: #3d4f59 !important;
	color: #3d4f59 !important;
}
.btn.btn-outline-secondary:hover {
	border-color: #3d4f59 !important;
	color: #fff !important;
	background-color: #3d4f59 !important;
}
.form-dropzone {
	background-color: #f2f2f2 !important;
	border-radius: 6px !important;
	/* border-style: dashed; */
	/* border-width: 1px; */
	/* border-color: #ccc !important; */
	width: 100% !important;
}
.form-dropzone > div:focus {
	outline: none !important;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: none !important;
}
.position .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
	border: 2px solid #fff;
	border-radius: 30px;
	width: 20px;
	height: 20px;
	background: red;
	color: #fff;
}
.c-switch-secondary .c-switch-input:checked + .c-switch-slider {
	background-color: #0054d9 !important;
	border-color: #0054d9 !important;
}
.c-switch-secondary .c-switch-input:checked + .c-switch-slider::before {
	border-color: #fff !important;
}
/*DataRangePicker*/
.DateRangePicker.DateRangePicker_1 {
	border-radius: 4px;
}
.DateInput {
	/* width: 105px !important; */
	width: 135px !important;
	font-family: 'MaisonNeue-Medium';
	margin-top: 4px !important;
	border-radius: 4px !important;
}
.DateRangePickerInput__withBorder {
	border-radius: 4px !important;
}
.DateInput_input {
	font-size: 14px !important;
	padding: 2px 12px !important;
	border-radius: 4px !important;
}
.DateRangePickerInput_arrow_svg {
	height: 16px !important;
	width: 16px !important;
	float: left;
	margin-top: -1px;
}
.DateInput_input__focused {
	border-color: transparent !important;
	color: #0054d9 !important;
}
.CalendarDay {
	vertical-align: middle;
	font-weight: normal;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
	background: #0054d9 !important;
	border: 1px double #0054d9 !important;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
	border-right: 33px solid #0054d9 !important;
	border-top: 33px ​solid transparen !important;
}
.DateInput_fang.DateInput_fang_1 {
	top: 30px !important;
}
.DateRangePicker_picker__directionLeft {
	top: 45px !important;
}
.CalendarMonth_caption {
	font-family: 'MaisonNeue-Medium';
}
/*header*/
.c-header {
	box-shadow: none !important;
}
.c-header-nav-link {
	color: #888 !important;
	font-family: 'MaisonNeue-Medium';
}
.c-header-nav-link.active {
	color: #0054d9 !important;
	font-family: 'MaisonNeue-Medium';
}
.CalendarDay__selected_span {
	/* background-color: #48d299 !important; */
	background-color: #e5efff !important;
	color: #000000 !important;
}
/*.c-header .c-subheader { background-color: #ebedef !important; }*/
.c-header .c-subheader {
	border-bottom: 1px solid #d8dbe0 !important;
}
.c-header .dropdown-item:hover,
.c-header .dropdown-item:focus {
	background-color: #e5efff;
}
.c-header .c-icon.logout {
	width: 14px !important;
}
.c-header a:hover,
a:focus {
	text-decoration: none !important;
}
/*body*/
.c-main {
	padding-top: 15px !important;
}
.breadcrumb-item a {
	color: #3c4b64 !important;
}
/*tabbar*/
/* .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	background: transparent !important;
} */
/* .nav-tabs .nav-link {
	color: #3c4b64;
	font-weight: 500 !important;
}
.nav-tabs .nav-link.active {
	color: #0054d9 !important;
	border-color: #ffffff #ffffff #0054d9 !important;
	border-bottom: 2px solid #0054d9 !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #ffffff #ffffff #0054d9 !important;
	border-bottom: 2px solid #0054d9 !important;
	font-weight: 500 !important;
} */
/* .tab-btns .btn.btn-tab {
	color: #0054d9;
	background: #fff;
	border: 1px solid #0054d9 !important;
	padding: 7px 10px !important;
}
.tab-btns .btn.btn-tab:hover {
	color: #fff;
	background: #0054d9;
	border: 1px solid #0054d9 !important;
	box-shadow: none !important;
}
.tab-btns .btn.btn-tab-active {
	color: #fff;
	background: #0054d9;
	border: 1px solid #0054d9 !important;
	box-shadow: none !important;
}
.nav-tabs {
	border-bottom: 1px solid;
	border-color: #e5e5e5 !important;
} */
.no-progress-bar .progress {
	display: block !important;
}
.progress {
	height: 10px !important;
}
.c-app:not(.c-legacy-theme)
	.btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']) {
	box-shadow: none !important;
}
.card-header {
	font-weight: bold;
}
.card-header a,
.card-header a:hover {
	color: #0054d9;
	font-weight: normal !important;
}
a,
a:hover {
	color: #0054d9;
}
.fa.fa-star {
	color: #fca016;
}
.page-title {
	font-size: 24px;
	margin-bottom: 0px;
	padding-top: 5px;
}
a.delete_btn {
	background-color: #df3535;
	border-radius: 4px;
}
a.delete_btn i {
	color: #fff;
	display: table;
	margin: 0 auto;
}
a.edit_btn {
	background-color: #3d4f59;
	border-radius: 4px;
}
a.edit_btn i {
	color: #fff;
	display: table;
	margin: 0 auto;
}
.c-switch-primary .c-switch-input:checked + .c-switch-slider {
	background-color: #0054d9 !important;
	border-color: #0054d9 !important;
}
.c-switch-primary .c-switch-input:checked + .c-switch-slider::before {
	border-color: #0054d9 !important;
}
.card-body h4 {
	font-size: 18px;
}

/*Table*/
.table thead th {
	border-bottom: 2px solid #eaeaea;
	font-size: 14px;
	white-space: nowrap;
	font-weight: 600;
}
/*reactTable*/
.ReactTable .rt-thead.-header {
	box-shadow: none !important;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
	font-size: 14px;
	white-space: nowrap;
	font-weight: 400;
	padding: 5px 20px !important;
	line-height: 24px !important;
	/* padding: 0.75rem !important; */
}
.pagination {
	margin-top: 15px;
}
.ReactTable .rt-th.table-header {
	align-self: center;
	text-align: left;
	font-family: 'MaisonNeue-Medium';
}
.ReactTable .rt-tr,
.ReactTable .rt-thead.-header {
	border-bottom: 1px solid #e5e5e5;
}
.ReactTable .rt-tbody .rt-td {
	align-self: center;
	text-align: left;
}
.ReactTable a i {
	display: table;
	margin: 0 auto;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
	/* background: #e5efff !important; */
}

.btn-tab.active {
	background: #0054d9 !important;
	color: #fff !important;
}
.transaction_header .filter_days a {
	padding: 4px 10px 7px !important;
}
.transaction_header .filter_days a {
	margin: 0px !important;
	border-radius: 4px !important;
	line-height: 1.5 !important;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.text-primary,
.text-green {
	color: #0054d9 !important;
}
.DateRangePicker_picker {
	z-index: 999;
}
.cust_info_sec p {
	margin-bottom: 0px;
}
a.link {
	color: #0054d9 !important;
}
.status-box .status {
	margin-bottom: 15px;
	padding-left: 60px;
	position: relative;
	min-height: 80px;
}
.status-box .status::after {
	position: absolute;
	top: 50px;
	left: 23px;
	content: '';
	display: block;
	height: 50px;
	width: 2px;
	background: #ccc;
}
.status-box .status p {
	margin-bottom: 5px;
	color: #4a5271;
}
.status-box .status.created {
	background: url('../images/icn_create_job.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.onTheWay {
	background: url('../images/icn_staff_on_way_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.reached {
	background: url('../images/icn_staff_reached_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.started {
	background: url('../images/icn_job_start_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.ended {
	background: url('../images/icn_job_finished_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.cancelled {
	background: url('../images/icn_job_cancel_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.invoice {
	background: url('../images/icn_generate_invoice_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.assigned {
	background: url('../images/icn_job_schedule_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.payment {
	background: url('../images/icn_invoice_paid_done.png') no-repeat left top;
	background-size: 50px;
}
.status-box .status.receipt {
	background: url('../images/icn_receipt_generated.png') no-repeat left top;
	background-size: 50px;
}

small {
	color: #a1a3b4;
}
hr {
	border-color: #e5e5e5 !important;
	margin: 15px 0;
}
.cell {
	border-radius: 4px;
	margin-bottom: 5px;
	border: 1px solid #e5e5e5;
}
.cell:hover,
.cell.read {
	/* background: #f6f7f8; */
}
.form-group label,
label {
	color: #5c6873;
	font-weight: 500;
}
.invoice-table .heading {
	background-color: #4f5d73 !important;
	color: #fff !important;
}
/*ul.nav.nav-tabs.hscroll {overflow-x: auto; display: flex;  flex-wrap: nowrap;  min-height: 41px;  overflow-y: hidden;}*/
.Toastify__toast {
	border-radius: 4px !important;
}
.regular {
	font-weight: 400 !important;
}
.DateRangePicker_picker {
	z-index: 2 !important;
}

@media (max-width: 1560px) {
	p {
		font-size: 14px;
	}
}
@media (max-width: 1023px) {
	.updates-wrapper {
		display: none;
	}
	.c-header-nav {
		width: 100%;
	}
	.c-header-nav-link.mobile {
		width: 80%;
	}
}
@media (max-width: 767px) {
	.login h4 {
		font-size: 24px;
	}
	.login .form {
		width: 320px;
	}
}
@media (max-width: 360px) {
	.p-5.bg-light {
		padding: 20px !important;
	}
}
.user_detail_img {
	width: 100px;
	height: 100px;
	background-color: green;
	opacity: 0.7;
	border: 1px solid #ccc;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.background_circle {
	background-color: green;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ccc;
	border-radius: 50px;
	margin-right: 10px;
}
.border_box {
	min-height: 100px;
}
.border_right {
	border-right: 1px solid #8c8c8c;
}
.font_bold {
	font-weight: bold;
	font-size: 17px;
}
.position {
	position: relative;
}
.img_position {
	position: absolute;
	right: -25px;
	top: -12px;
}
.field-icon {
	float: right;
	margin-right: 12px;
	margin-top: -25px;
	position: relative;
	z-index: 23;
}
.text-blue {
	color: #1d7cf2 !important;
}

.z-index-select {
	z-index: 33;
}
.z-index-select2 {
	font-size: 15px !important;
	z-index: 2;
}
.header-fc-logo {
	height: 32px;
	width: 32px;
}

.invoice_header_bar {
	padding: 15px 15px;
	background-color: #e5efff !important;
	color: #0054d9 !important;
}

.image_company_logo {
	position: relative;
	width: 100%; /* for IE 6 */
}
.spin-loading {
	/* position: absolute; 
  top: 40%;  */
	left: 0;
	width: 100%;
}

/* indra style changes starts */
.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
	top: 45px !important;
}

.btn-dark.btn-active {
	color: #fff;
	background-color: #4d5666 !important;
	border-color: #48505f !important;
}

.fc .fc-timeline-body {
	min-height: 100%;
	position: relative;
	z-index: 0 !important;
}

div.react-datepicker-popper {
	z-index: 33333 !important;
	width: 238px;
}

.resourceHeader {
	font-weight: bold;
}

/* indra style changes ends */
.lableHome {
	color: #161616 !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	font-family: 'Roboto', sans-serif !important;
}
.f-family-text {
	font-family: 'Roboto', sans-serif !important;
}
.apple_btn {
	background: rgb(0, 0, 0) !important;
	color: rgb(255, 255, 255) !important;
	margin-top: 10px !important;

	box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.apple_btn:hover {
	background: #fff;
	border: 1px solid #fff;
	color: #000;
}
.apple_btn i.icon {
	background: url('../images/icn_apple.png') no-repeat center center;
	background-size: 18px;
	width: 18px;
	height: 18px;
	display: inline-block;
	margin-right: 5px;
	vertical-align: -0.25em;
}
