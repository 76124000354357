/*************** LOGIN SIGNUP NEW CSS *********************/
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;500;600;700&display=swap');

.wrapper-login {
	height: 100vh;
	background-color: #f8f9fa;
}

.wrapper-login > .d-flex {
	height: 100%;
}

.invalidFormInput {
	border: 1px solid #dc3545 !important;
}

.pd-0 {
	padding: 0 !important;
}

.d-show {
	display: none;
}

.disablePhoneInput {
	pointer-events: none;
}

.add-modal.modal .modal-content {
	border-radius: 10px;
	box-shadow: 0px 1px 3px #00000029;
	-webkit-box-shadow: 0px 1px 3px #00000029;
	-moz-box-shadow: 0px 1px 3px #00000029;
	border: 0;
	font-family: 'Roboto', sans-serif;
}
.add-modal.modal .modal-header {
	border-bottom: 1px solid #dcdcdc;
	padding: 1rem 25px;
}
.add-modal.modal .modal-header h5 {
	font-size: 18px;
	color: #17161d;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}
.add-modal.modal .close {
	margin: 0 !important;
	padding: 0;
	right: 15px;
	top: 25px;
}
/*.add-modal.modal .modal-content .form-group label{font-weight: 400;}*/
.add-modal.modal .modal-body {
	padding: 25px 25px 5px;
}
.add-modal.modal .modal-dialog {
	max-width: 620px;
}
.add-modal.modal select.custom-select {
	background-position: 95% 15px;
}
.add-modal.modal .col-md-12 select.custom-select {
	background-position: 98.5% 15px;
}
.add-modal.modal .form-check-label {
	padding-left: 5px;
	padding-top: 5px;
	font-weight: 400;
}
.add-modal.modal .form-check-input {
	top: 2px;
	width: 14px;
	height: 14px;
	border: 1px solid #dcdcdc;
}
.modal-footer {
	border-top: 1px solid #dcdcdc;
	padding: 1rem 25px;
}
.modal-footer .btn {
	min-width: inherit;
	box-sizing: border-box;
	font-size: 14px;
	padding: 8px 15px;
}
.modal-footer .btn.btn-secondary {
	box-shadow: none;
	height: 38px;
	line-height: 38px;
	margin-bottom: 0;
}

.bg-image-block {
	background: url(../../images/images/background-image.png) no-repeat 100% 100%;
	background-size: cover;
	display: flex;
	justify-content: center;
}

.login-content {
	display: flex;
	justify-content: center;
	background: #f8f9fa;
}

.login-content .logo {
	display: block;
	margin: 35px 0 30px;
}

.login-content .logo img {
	display: block;
	margin: 0 auto;
}

.login-box {
	max-width: 587px;
	background: #ffffff;
	box-shadow: 0px 6px 12px #00000029;
	margin: 0 auto 20px;
	padding: 25px 30px;
	font-family: 'Be Vietnam', sans-serif;
	font-weight: 400;
}

.login-box .login-title {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;
	color: #302a32;
}

.login-box .login-header {
	padding-bottom: 25px;
}

.login-box .login-header p {
	margin-bottom: 0;
	font-size: 14px;
	line-height: 14px;
	color: #000000;
	font-weight: 600;
}

.login-box .login-header p a {
	color: #0155d9;
	font-weight: 600;
	text-decoration: underline;
}

.login-box .form-group label {
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	color: #302a32;
	margin-bottom: 10px;
}

.login-box .form-control {
	border: 1px solid #d7d7d7;
	border-radius: 5px;
	height: 42px;
	font-size: 14px;
	font-weight: 400;
	line-height: 42px;
	color: #302a32;
}

.login-box .form-control:focus {
	border-color: #0054d9;
	box-shadow: none;
}

.login-box .form-control::-webkit-input-placeholder {
	color: #d9d9d9;
}

.login-box .form-control::-moz-placeholder {
	color: #d9d9d9;
}

.login-box .form-control:-ms-input-placeholder {
	color: #d9d9d9;
}

.login-box .form-control:-moz-placeholder {
	color: #d9d9d9;
}

.login-box a {
	color: #0155d9;
	font-size: 14px;
	display: inline-block;
}

.login-box a.forgot-link {
	text-decoration: underline;
	font-weight: 500;
	margin: 10px 0 15px;
}

.login-box a.d-show {
	display: none;
}

.input-wrap {
	position: relative;
}

.input-wrap .form-control {
	padding-right: 40px;
}

.input-wrap span {
	position: absolute;
	right: 12px;
	top: 8px;
}

.input-group {
	width: 76px;
	position: absolute;
	left: 0;
	background: #efefef;
}

.input-group-outer {
	position: relative;
	padding-left: 85px;
}

.input-group-text {
	border: 1px solid #d7d7d7;
	border-radius: 5px;
	width: 38px;
	text-align: center;
	padding: 0;
}

.input-group-text img {
	display: block;
	margin: 0 auto;
}

.input-group .form-control {
	padding: 0 5px;
	text-align: center;
}

.login-box .btn {
	height: 42px;
	font-size: 16px;
	width: 100%;
	line-height: normal;
	border-radius: 5px;
}

.login-box .btn.btn-blue {
	background: #0155d9;
	color: #ffffff;
	font-weight: 500;
	padding-bottom: 34px;
}

.login-box .bottom-block p {
	font-size: 14px;
	color: #302a32;
	font-weight: 500;
	text-align: center;
	margin: 10px 0 0;
}

.login-box .bottom-block p a {
	font-weight: 600;
	color: #0155d9;
}

.login-box .btn.btn-google {
	background: #ffffff;
	border: 1px solid #d7d7d7;
	font-weight: 600;
	color: #1e1e1e;
	padding-left: 20px;
}

.login-box .btn.btn-google i {
	position: relative;
	top: -3px;
	left: -8px;
}

.or-block {
	position: relative;
	z-index: 0;
}

.or-block span {
	font-size: 14px;
	color: #000000;
	font-weight: 600;
	display: block;
	margin: 15px auto;
	position: relative;
	width: 37px;
	height: 21px;
	text-align: center;
	background: #ffffff;
	z-index: 2;
}

.or-block:after {
	position: absolute;
	content: '';
	width: 100%;
	background: #d9d9d9;
	height: 1px;
	left: 0;
	right: 0;
	z-index: 1;
	top: 11px;
}

@media (max-width: 1399px) {
	.login-content .logo {
		margin: 15px 0 10px;
	}
	.login-content .logo img {
		width: 150px;
	}
	.login-box {
		padding: 15px 30px;
	}
	.login-box .login-header {
		padding-bottom: 15px;
	}
	.login-box .form-group {
		margin-bottom: 10px;
	}
	.login-box .form-group label {
		margin-bottom: 7px;
	}
	.login-box .form-control {
		height: 40px;
	}
	.or-block span {
		margin: 10px auto;
	}
}

@media (max-width: 1199px) {
	.login-box {
		padding: 15px 20px;
	}
}

@media (max-width: 991px) {
	.bg-image-block {
		display: none;
	}
	.login-content .logo {
		margin: 25px 0 20px;
	}
}

@media (max-width: 575px) {
	.login-content {
		padding-bottom: 25px;
	}
	.login-content .logo {
		margin: 20px 0 15px;
	}
	.login-content .logo img {
		width: 150px;
	}
	.login-box .login-title {
		font-size: 22px;
		line-height: 22px;
		padding-bottom: 15px;
	}
	.login-box .login-header {
		display: block !important;
	}
}

/**************** LOGIN SIGNUP CSS ENDS ********************/
